import React, {useContext} from 'react';
import {ProofOfSatoshiCompoundWrapper} from "../ProofOfSatoshiCompoundWrapper";
import Button from "../../../../components/common/Button";
import {ThemeContext} from "../../../../index";
import {useBtcAddressAction, useBtcAddressValue} from "../context/BtcAddressContext";
import {useSatoshiActions} from "../context/SatoshiStepContext";




const Satoshi2 = () => {

    const theme = useContext(ThemeContext);
    const inputValue = useBtcAddressValue();
    const {handleText} = useBtcAddressAction();
    const {satoshiStepIncrease} = useSatoshiActions();

    const validTextToBoolean: boolean = inputValue.get('from').length >= 26;

    return (
        <>
            <ProofOfSatoshiCompoundWrapper>
                <ProofOfSatoshiCompoundWrapper.P classNames={'mt-2 font-bold'}>
                    Input your BTC Address to verify ownership.
                </ProofOfSatoshiCompoundWrapper.P>
                <ProofOfSatoshiCompoundWrapper.Input
                    name={"from"}
                    handleChange={handleText}
                    placeholder={'BTC Wallet Address'}
                    classNames={'mt-2'}
                    validText={validTextToBoolean ? "BTC Address valid" : "BTC Address invalid"}
                    validTextClassNames={validTextToBoolean ? "text-blue-500" : "text-rose-500"}
                />
            </ProofOfSatoshiCompoundWrapper>
            <Button onClick={satoshiStepIncrease}
                    conditionalClassName={
                        validTextToBoolean ?
                            {
                                backgroundColor: theme.mainColor,
                                color: theme.subColor,
                                margin: 0
                            } :
                            {
                                backgroundColor: theme.mainColor,
                                color: theme.subColor,
                                opacity: 0.5,
                                margin: 0
                            }
                    }
            />
        </>

    )
}

export default Satoshi2
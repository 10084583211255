import React, {useContext, useEffect} from 'react';
import {ProofOfSatoshiCompoundWrapper} from "../ProofOfSatoshiCompoundWrapper";
import Button from "../../../../components/common/Button";
import {ThemeContext} from "../../../../index";
import {useSatoshiActions} from "../context/SatoshiStepContext";







const Satoshi1 = () => {

    const theme = useContext(ThemeContext);
    const {satoshiStepIncrease} = useSatoshiActions()

    return (
        <>
            <ProofOfSatoshiCompoundWrapper>
                <ProofOfSatoshiCompoundWrapper.P classNames={'mt-2'}>
                    This method proceeds by receiving a small amount of BTC for verification from the applicant's
                    BTC address.
                </ProofOfSatoshiCompoundWrapper.P>
                <ProofOfSatoshiCompoundWrapper.P classNames={'mt-2'}>
                    Received BTC is non-refundable, and users are solely responsible for incorrect transfers.
                </ProofOfSatoshiCompoundWrapper.P>
                <ProofOfSatoshiCompoundWrapper.P classNames={'mt-2'}>
                    The network fee required for BTC transfer is borne by the user.
                </ProofOfSatoshiCompoundWrapper.P>
            </ProofOfSatoshiCompoundWrapper>
            <Button onClick={satoshiStepIncrease} conditionalClassName={true ? {backgroundColor: theme.mainColor, color: theme.subColor} : {backgroundColor: theme.mainColor, color: theme.subColor, opacity: 0.5}}/>
        </>

    )
}

export default Satoshi1
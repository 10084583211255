import React, {useRef, useState} from 'react';
import EachCoinArticle from "./EachCoinArticle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowDown} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useHorizontalScroll from "../hooks/useHorizontalScroll";
import useScrollPosition from "../hooks/useScrollPosition";
import {IActivityCoins, IWallet} from "../types/type";


interface IBlockChainNetworkList {
    step: number
    activityCoin: IActivityCoins | null
    wallet?: IWallet[]
}

const BlockChainNetworkList: React.FC<IBlockChainNetworkList> =
    ({
         step,
         activityCoin,
         wallet
     }) => {

        const [showBlockChainList, setShowBlockChainList] = useState<boolean>(false);
        const {t} = useTranslation();
        const scrollElement = useRef<HTMLUListElement>(null);
        useHorizontalScroll(scrollElement);

        const scrollPositionRef = useRef<HTMLUListElement>(null);
        const scrollPosition = useScrollPosition(scrollPositionRef);

        return (
            <article className={'flex flex-col my-1.5'}>
                {
                    step === 0 ?
                        <h3 className={'my-1.5 text-sm font-bold text-sm'}>{t('block-chain-network-0')}</h3>
                        :
                        <div className={'text-xxxs flex justify-between items-center'}>
                            <h3 className={'my-1.5 font-bold'}>{t('block-chain-network-1')}</h3>
                            <div className={`flex items-center`}>
                                <input id={'hide-chain-list'} type={'checkbox'} checked={showBlockChainList}
                                       onChange={() => setShowBlockChainList(prev => !prev)}/>
                                <label htmlFor={'hide-chain-list'}
                                       className={'mx-1 cursor-pointer'}>{t('hide-verified')}</label>
                            </div>
                        </div>
                }


                <ul ref={step === 0 ? scrollPositionRef : scrollElement} className={`overflow-auto relative`}
                    style={{'maxHeight': '250px'}}>
                    <div className={`text-sm ${step === 2 && ' flex gap-3 p-1'}`}
                         style={{'whiteSpace': step === 0 ? 'pre-wrap' : 'nowrap'}}>
                        {
                            activityCoin && (Object.keys(activityCoin) as (keyof typeof activityCoin)[]).map((coinKey) =>
                                <EachCoinArticle key={coinKey}
                                                 activityCoin={activityCoin}
                                                 coinKey={coinKey}
                                                 step={step}
                                                 wallet={wallet}
                                                 showBlockChainList={showBlockChainList}
                                />
                            )
                        }
                    </div>
                </ul>
                {
                    step === 0 && scrollPosition < 5 &&
                    <div
                        className={'w-max absolute bottom-[120px] left-[50%] right-[50%] flex items-center justify-center text-xxxs'}
                        style={{'transform': 'translate(-50%,-50%)'}}
                    >
                        <span className={'font-bold'}>Scroll Down</span>
                        <FontAwesomeIcon icon={faCircleArrowDown} className={'ml-1'}/>
                    </div>
                }
            </article>
        )
    }
export default BlockChainNetworkList
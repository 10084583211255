import React, {useEffect, useCallback, Suspense, useMemo, useState, Dispatch, SetStateAction} from 'react';
import {ICommonPage} from "../../types/commonModal";
import {IActivityCoins, IIdStatus, IProjectStatus, IUser, IWallet} from "../../types/type";
import WalletContentsWrapper from "../../components/common/WalletContentsWrapper";
import RingsLoaderMemo from "../../components/common/Loader";
import PreAuthenticationBottom from "../../components/PreAuthenticationBottom";
import {replaceProjectCoinTicker} from "../../util";
import {useTranslation} from "react-i18next";
import {useProjectOptionValue} from "../../context/ArgosIdOption";

interface IAuthenticationCompleted extends ICommonPage {
    activityCoin: IActivityCoins | null
    loading: boolean
    liveFormResult: IIdStatus | null
    step: number,
    setStep?: Dispatch<SetStateAction<number>>
    wallet?: IWallet[]
    goNextStep: (param ?: boolean, forceStep ?: number) => void,
    user?: IUser
    projectId: string
    projectStatus: IProjectStatus
    chains: string | string[]
    env : "dev" | "live"
    collectPersonalData : boolean
}


const PreAuthentication: React.FC<IAuthenticationCompleted> =
    ({
         activityCoin,
         loading,
         goNextStep,
         liveFormResult,
         step,
         wallet,
         user,
         projectId,
         projectStatus,
         chains,
        env,
         collectPersonalData
     }) => {

        const {t} = useTranslation();
        const [allTheCoinsAreCertified , setAllTheCoinsAreCertified] = useState<boolean>(false);
        const {optionArgosId} = useProjectOptionValue()

        useEffect(() => {
            if (wallet && activityCoin) {
                const projectCoinKey = (Object.keys(activityCoin) as (keyof typeof activityCoin)[])
                    .filter((coinKey) => activityCoin[coinKey])
                    .map((coinKey) => replaceProjectCoinTicker(coinKey));

                let validationRes = projectCoinKey.every((key: string) => !!wallet.find((wallet) => wallet.coinTicker === key));

                return setAllTheCoinsAreCertified(validationRes && (liveFormResult === 'approved' || !optionArgosId))
            }
            return setAllTheCoinsAreCertified(false);
        },[wallet , liveFormResult])



        if (loading) {
            return <RingsLoaderMemo/>
        }


        return (
            <section className={'flex flex-col'}>
                <p className={'text-center text-sm mt-1.5'}>{t('following2')}</p>
                <WalletContentsWrapper activityCoin={activityCoin} liveFormResult={liveFormResult} step={step} wallet={wallet} collectPersonalData={collectPersonalData}/>
                <PreAuthenticationBottom
                    collectPersonalData={collectPersonalData}
                    liveFormResult={liveFormResult}
                    AllTheCoinsAreCertified={allTheCoinsAreCertified}
                    step={step}
                    goNextStep={goNextStep}
                    user={user}
                    projectId={projectId}
                    projectStatus={projectStatus}
                    chains={chains}
                    env={env}
                />
            </section>
        )

    }
export default PreAuthentication
import React, {CSSProperties, memo, useCallback, useMemo} from 'react';
import MemoCoinImage from "./CoinImage";
import polygon from "../assets/polygon.svg";
import eth from "../assets/ethereum.svg";
import binance from "../assets/binance.svg";
import klaytn from "../assets/klaytn.svg";
import solana from "../assets/solana.svg";
import metamask from "../assets/metamask.svg";
import phantom from "../assets/phantom.png";
import walletconnector from "../assets/walletconnector.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faCopy} from "@fortawesome/free-solid-svg-icons";
import {IWallet} from "../types/type";
import {toastSuccess} from "../toast";
import {useTranslation} from "react-i18next";
import bitcoin from "../assets/bitcoin.svg";
import avalanche from "../assets/avalanche.svg";
import arbitrum from "../assets/arbitrum.svg";
import optimism from "../assets/optimism.svg";
import fantom from "../assets/fantom.svg";
import cronos from "../assets/cronos.png";
import harmony from "../assets/harmony.svg";
import huobi from "../assets/huobi.svg";


interface IEachCarousel {
    wallet : IWallet
    deleteWallet : (index : number) => void
    seq : number
}


const EachCarousel : React.FC<IEachCarousel> = ({wallet , deleteWallet , seq}) => {


    const {t} = useTranslation()

    const findCoinImage = useCallback((walletChainId: string): React.ReactNode => {
        const smallLogoCSS: CSSProperties = {width: '30px', height: '30px'}

        const coinList : { [key: string]: React.ReactNode } = {
            '137' : <MemoCoinImage bgColor={'matic-bg-color'} logoSrc={polygon} coinName={'Polygon'} style={smallLogoCSS}/>,
            '1' : <MemoCoinImage bgColor={'eth-bg-color'} logoSrc={eth} coinName={'Ethereum'} style={smallLogoCSS}/>,
            '56' : <MemoCoinImage bgColor={'bsc-bg-color'} logoSrc={binance} coinName={'binance'} style={smallLogoCSS}/>,
            '8217' : <MemoCoinImage bgColor={'klay-bg-color'} logoSrc={klaytn} coinName={'klaytn'} style={smallLogoCSS}/>,
            'sol' : <MemoCoinImage bgColor={'sol-bg-color'} logoSrc={solana} coinName={'solana'} style={smallLogoCSS}/>,
            'btc' : <MemoCoinImage bgColor={'btc-bg-color'} logoSrc={bitcoin} coinName={'bitcoin'} style={smallLogoCSS}/>,
            '43114' : <MemoCoinImage bgColor={'avalanche-bg-color'} logoSrc={avalanche} coinName={'avalanche'} style={smallLogoCSS}/>,
            '42161' : <MemoCoinImage bgColor={'arbitrum-bg-color'} logoSrc={arbitrum} coinName={'arbitrum'} style={smallLogoCSS}/>,
            '10' : <MemoCoinImage bgColor={'optimism-bg-color'} logoSrc={optimism} coinName={'optimism'} style={smallLogoCSS}/>,
            '250' :<MemoCoinImage bgColor={'fantom-bg-color'} logoSrc={fantom} coinName={'fantom'} style={smallLogoCSS}/>,
            '25' :<MemoCoinImage bgColor={'cronos-bg-color'} logoSrc={cronos} coinName={'cronos'} style={smallLogoCSS}/>,
            '1666600000' :<MemoCoinImage bgColor={'bg-white'} logoSrc={harmony} coinName={'cronos'} style={smallLogoCSS}/>,
            '61' : <MemoCoinImage bgColor={'eth-classic-bg-color'} logoSrc={eth} coinName={'Ethereum'} style={smallLogoCSS}/>,
            '128' : <MemoCoinImage bgColor={'houbi-bg-color'} logoSrc={huobi} coinName={'Huobi ECO Chain'} style={smallLogoCSS}/>
        }

        return coinList[walletChainId]
    },[])


    const findWalletIcon = useCallback((walletName: string): React.ReactNode => {
        const toLowerWalletName = walletName.toLowerCase();

        const walletList : {[key : string] : React.ReactNode} = {
            'metamask' : <img className={'shadow-lg rounded-full p-0.5'} src={metamask} alt={'metaMask icon'} style={{'height': '30px'}}/>,
            'phantom' : <img className={'shadow-lg rounded-full p-0.5'} src={phantom} alt={'phantom icon'} style={{'height': '30px'}}/>,
            'walletconnect' : <img src={walletconnector} alt={'walletconnector icon'} style={{'height': '18px'}}/>,
            'satoshi' : <span>Proof of Satoshi</span>
        }
        return walletList[toLowerWalletName]
    },[])


    const cardBackColor = useCallback((chainId: string): string => {
        const cardBackColorList: { [key: string]: string } = {
            '137': ' bg-gradient-to-r from-violet-300 to-violet-400',
            '1': ' bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400',
            '56': ' bg-amber-300 text-white bg-gradient-to-l from-yellow-200 to-yellow-500',
            '8217': ' bg-gradient-to-r from-orange-400 to-rose-400',
            'sol': ' bg-gradient-to-r from-green-300 via-blue-500 to-purple-600',
            'btc': ' bg-gradient-to-r from-orange-400 to-amber-200',
            '43114' : ' bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500',
            '42161' : ' bg-[radial-gradient(ellipse_at_right,_var(--tw-gradient-stops))] from-sky-400 to-indigo-900',
            '10' : ' bg-gradient-to-r from-rose-300 via-rose-500 to-rose-600',
            '250' :' bg-sky-300',
            '25' :' bg-gradient-to-r from-sky-400 to-blue-500',
            '1666600000' : ' bg-gradient-to-r from-green-200 via-green-300 to-blue-500',
            '61' : ' bg-gradient-to-r from-green-200 to-green-500',
            '128' : ' bg-gradient-to-r from-yellow-400 via-gray-50 to-teal-300'
        }
        return cardBackColorList[chainId]
    }, [])


    const sliceWalletAddress = useMemo(() =>
            wallet.address.slice(0, 5) + "..." + wallet.address.slice((wallet.address.length - 1) - 5, wallet.address.length)
    , [wallet.address])


    const copyAddress = useCallback((walletAddress : string) => {
        navigator.clipboard.writeText(walletAddress)
        return toastSuccess(t('wallet-address-copied'))
    },[])


    return (
        <article className={'w-full h-full text-xs p-6 rounded-xl shadow-inner' + cardBackColor(wallet.chainId)}>
            <div className={'flex w-full justify-end'} onClick={() => deleteWallet(seq)}>
                <FontAwesomeIcon icon={faCircleXmark} className={'ml-1.5 text-red cursor-pointer'}/>
            </div>
            <div className={'flex items-center my-1.5'}>
                <p>{t('wallet-address')} : {sliceWalletAddress}
                    <FontAwesomeIcon
                        onClick={() => copyAddress(wallet.address)}
                        icon={faCopy}
                        className={'text-slate-600 ml-0.5 cursor-pointer'}/>
                </p>

            </div>
            <div className={'flex items-center mt-3'}>
                <span className={'mr-3'}>{t('block-chain')} : </span>
                {findCoinImage(wallet.chainId)}
            </div>
            <div className={'flex items-center mt-2'}>
                <span className={'mr-1.5'}>{t('wallet-type')} : </span>
                {findWalletIcon(wallet.walletName)}
            </div>
        </article>
    );
}

export default memo(EachCarousel)
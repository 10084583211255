import React, {createContext, PropsWithChildren, useState, useMemo, useContext, useEffect} from 'react';


interface ISatoshiStepContextAction {
    satoshiStepIncrease: () => void
    satoshiStepDecrease: () => void
    decreaseFromParam: (param: number) => void
    reset: () => void
}


const SatoshiStepContext = createContext<number | null>(null);
const SatoshiStepActionContext = createContext<ISatoshiStepContextAction | null>(null);

export function SatoshiStepProvider({children}: PropsWithChildren) {
    const [satoshiStep, setSatoshiStep] = useState<number>(0);

    const actions: ISatoshiStepContextAction = useMemo(() => ({
        satoshiStepIncrease() {
            setSatoshiStep(prev => prev + 1)
        },
        satoshiStepDecrease() {
            setSatoshiStep(prev => prev - 1);
        },
        decreaseFromParam(param: number) {
            setSatoshiStep(prev => prev - param)
        },
        reset() {
            setSatoshiStep(0)
        }
    }), [])

    useEffect(() => actions.reset(), [])

    return (
        <SatoshiStepActionContext.Provider value={actions}>
            <SatoshiStepContext.Provider value={satoshiStep}>
                {children}
            </SatoshiStepContext.Provider>
        </SatoshiStepActionContext.Provider>
    )
}

export function useSatoshiStep() {
    const value = useContext(SatoshiStepContext);
    if (typeof value !== 'number' && !value) throw new Error('useSatoshiStep should be used within SatoshiStepProvider');
    return value
}

export function useSatoshiActions() {
    const actions = useContext(SatoshiStepActionContext);
    if (!actions) throw new Error('useSatoshiActions should be used within SatoshiStepProvider');
    return actions
}
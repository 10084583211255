import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {BtcAddressProvider} from "./context/BtcAddressContext";
import {SatoshiStepProvider} from "./context/SatoshiStepContext";
import ProofOfSatoshiContainer from "./ProofOfSatoshiContainer";
import {IWallet} from "../../../types/type";


interface IProofOfSatoshi {
    setStep: Dispatch<SetStateAction<number>>
    setPrevPageAnimation: Dispatch<SetStateAction<boolean>>
    setWallet: Dispatch<SetStateAction<IWallet[]>>
}


const ProofOfSatoshi: React.FC<React.PropsWithChildren<IProofOfSatoshi>> =
    ({
         setStep,
         setPrevPageAnimation,
         setWallet
     }) => {



        const goBackWalletPage = useCallback(() => {
            setPrevPageAnimation(true);
            setStep(2)
        }, [])

        return (
            <SatoshiStepProvider>
                <BtcAddressProvider>
                    <ProofOfSatoshiContainer
                        setWallet={setWallet}
                        goBackWalletPage={goBackWalletPage}
                    />
                </BtcAddressProvider>
            </SatoshiStepProvider>
        )
    }

export default ProofOfSatoshi
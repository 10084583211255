import React from 'react';
import chrome from '../assets/chrome.svg';
import brave from '../assets/brave.svg';
import firefox from '../assets/firerfox.svg';
import edge from '../assets/edge.svg';
import {useTranslation} from "react-i18next";

const NotSupportedWalletsList = () => {

    const {t} = useTranslation()

    return (
        <article className={'flex flex-col w-full text-sm'}>
            <p>{t('not-supported-1')}</p>
            <p>{t('not-supported-2')}</p>
            <article className={'flex gap-7 mt-4 '}>
                <div className={'flex flex-col justify-center items-center'} style={{'width':'50px'}}>
                    <img src={chrome} alt={"chrome Icon"} width={'50px'}/>
                    <span className={'mt-1.5'}>Chrome</span>
                </div>
                <div className={'flex flex-col justify-center items-center'} style={{'width':'50px'}}>
                    <img src={brave} alt={"brave Icon"} width={'50px'}/>
                    <span className={'mt-1.5'}>Brave</span>
                </div>
                <div className={'flex flex-col justify-center items-center'} style={{'width':'50px'}}>
                    <img src={firefox} alt={"chrome Icon"} width={'50px'}/>
                    <span className={'mt-1.5'}>Firefox</span>
                </div>
                <div className={'flex flex-col justify-center items-center'} style={{'width':'50px'}}>
                    <img src={edge} alt={"chrome Icon"} width={'50px'}/>
                    <span className={'mt-1.5'}>Edge</span>
                </div>
            </article>
        </article>

    )
}
export default NotSupportedWalletsList
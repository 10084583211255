import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRobot, faCopy} from "@fortawesome/free-solid-svg-icons";
import {toastSuccess} from "../../toast";
import {useTranslation} from "react-i18next";
import SmallLoading from "../../components/SmallLoading";

export interface IArgosIdError {
    userEmail: string
}

const ProjectStatusClose: React.FC<IArgosIdError> = ({userEmail}) => {
    const {t} = useTranslation()

    const copyAddress = () => {
        navigator.clipboard.writeText(userEmail)
        return toastSuccess(`${t('email-copy')}`)
    }
    return (
        <section className={'flex flex-col'}>

            <p className={'text-center text-base mt-2 font-semibold'}>ARGOS ID</p>
            <p className="flex justify-center">
                <FontAwesomeIcon icon={faRobot} className={'text-red-200 text-6xl mt-6 mb-6'}/>
            </p>
            <p className={'text-center text-xl mt-2'}>
                {t('oops-1')}
            </p>
            <p className={'text-center text-xl mt-2'}>{t('oops-2')}</p>
            <p className={'text-center text-xl mt-2'}>{t('oops-3')}</p>

            {userEmail ? (
                <span className="flex items-center justify-center">
                <p className={'text-center text-base mt-3 mb-2 text-blue-600 cursor-pointer hover:underline'}
                   onClick={copyAddress}>{userEmail}</p>
                <FontAwesomeIcon icon={faCopy} className={'text-gray-400 text-sm ml-1 cursor-pointer'}
                                 onClick={copyAddress}/>
            </span>
            ) : (
                <span className="flex items-center justify-center mt-2">
                    <SmallLoading/>
                </span>
            )}
        </section>
    );
};
export default ProjectStatusClose;
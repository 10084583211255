import React, {Dispatch, SetStateAction, useCallback, useContext, useEffect, useState} from 'react';
import {GoToChangeNetworkParam, IActivityCoins, ICurrentSelectNetwork, IWallet} from "../types/type";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {replaceProjectCoinTicker} from "../util";
import {ThemeContext} from "../index";
import {CHAIN_NETWORK_LIST} from "../constant";


interface IVerifyPersonWallet {
    currentSelectNetwork: ICurrentSelectNetwork
    setCurrentSelectNetwork: Dispatch<SetStateAction<ICurrentSelectNetwork>>
    activityCoin: IActivityCoins | null
    wallet: IWallet[]
    goToChangeNetwork : (step : GoToChangeNetworkParam) => void;
}


const VerifyPersonWallet: React.FC<IVerifyPersonWallet> =
    ({

         currentSelectNetwork,
         setCurrentSelectNetwork,
         activityCoin,
         wallet,
        goToChangeNetwork
     }) => {

        const {t} = useTranslation()
        const theme = useContext(ThemeContext);




        useEffect(() => {
            if (!activityCoin || currentSelectNetwork.chainId || currentSelectNetwork.symbol) return
            const onlyTrueActivityCoinsTicker = Object.entries(activityCoin).filter(([_, value]) => value).map(item => replaceProjectCoinTicker(item[0]));
            const notYetCertified = onlyTrueActivityCoinsTicker.filter(coinKey => !wallet.find(eachWallet => (eachWallet.coinTicker === coinKey)))
            const firstNotYetCertified = notYetCertified[0] === 'bsc' ? 'bnb' : notYetCertified[0];

            const network = CHAIN_NETWORK_LIST.find((network) => network.symbol.toLowerCase() === firstNotYetCertified);
            if (network) setCurrentSelectNetwork(network)
        },[])






        return (
            <section className={'my-3 flex flex-col text-sm font-bold'}>
                <h4 className={'my-1.5'}>{t('verify-person-wallet')}</h4>
                <h5 className={'text-sm'}>1. {t('choose-network')}</h5>
                <div className={'flex items-center mt-3'}>
                    <div>{
                        currentSelectNetwork.chainId ?
                            currentSelectNetwork.logo
                            :
                            t('failed-to-detect-network')
                    }
                    </div>
                    {
                        activityCoin && Object.values(activityCoin).filter(bool => bool).length >= 2 &&
                        <button
                            className={'rounded-md font-bold border-solid px-1.5 py-1 text-xs text-white ml-1.5'}
                            style={{
                                backgroundColor : theme.mainColor,
                                color : theme.subColor
                            }}
                            onClick={() => goToChangeNetwork(999)}>
                            {t('change-network')}
                            <FontAwesomeIcon icon={faArrowCircleRight} className={'ml-1.5'}/>
                        </button>
                    }

                </div>

            </section>
        )
    }
export default VerifyPersonWallet
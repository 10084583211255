import React, {useCallback, useMemo, useRef, useState} from 'react';
import MemoCoinImage from "../CoinImage";
import argos from "../../assets/argos.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCircleXmark, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {IActivityCoins, IIdStatus, IWallet} from "../../types/type";
import {useTranslation} from "react-i18next";
import {useProjectOptionValue} from "../../context/ArgosIdOption";
import BlockChainNetworkList from "../BlockChainNetworkList";


interface IWalletContents {
    activityCoin: IActivityCoins | null
    liveFormResult: IIdStatus | null
    step: number
    wallet?: IWallet[]
    collectPersonalData : boolean
}


const WalletContentsWrapper: React.FC<IWalletContents> =
    ({
         liveFormResult,
         activityCoin,
         step,
         wallet,
         collectPersonalData
     }) => {


        const {t} = useTranslation();
        const {optionArgosId} = useProjectOptionValue();







        const activityCoinWithAtLeastOne = useMemo(() => {
            if (activityCoin) {
                const res = Object.values(activityCoin).find(value => value);
                if (res) return true
            }
            return false
        }, [activityCoin])



        const argosValidationIcon = useCallback((): React.ReactNode => {
            if (liveFormResult === IIdStatus.notApproved || liveFormResult === IIdStatus.rejected) {
                return <FontAwesomeIcon icon={faCircleXmark} className={'ml-1.5 text-rose-600'}/>
            }
            if (liveFormResult === IIdStatus.pending) {
                return <FontAwesomeIcon icon={faExclamationCircle} className={'ml-1.5 text-purple-500'}/>
            }
            if (liveFormResult === IIdStatus.approved) {
                return <FontAwesomeIcon icon={faCircleCheck} className={'ml-1.5 text-lime-600'}/>
            }
            return <FontAwesomeIcon icon={faCircleXmark} className={'ml-1.5 text-rose-600'}/>
        }, [liveFormResult])


        const argosValidationText = useCallback((): React.ReactNode => {
            const commonTextClass = 'mt-1.5 text-sm '

            if (liveFormResult === IIdStatus.pending) {
                return <p className={`${commonTextClass} text-purple-600`}>{t('kyc-pending-1')}<br/>{t('kyc-pending-2')}</p>
            }
            if (liveFormResult === IIdStatus.rejected) {
                return <p className={`${commonTextClass} text-rose-600`}>{t('kyc-reject-1')}<br/>{t('kyc-reject-2')}</p>
            }

            if (liveFormResult === IIdStatus.notApproved) {
                return <p className={`${commonTextClass} text-rose-600`}>{t('need-to-generate-ARGOS-ID')}</p>
            }
            return
        }, [liveFormResult])


        return (
            <>
                    {/*user stauts가 closed이면 */}

                    {
                        step === 0 && optionArgosId &&
                        <article className={'flex flex-col text-sm my-3'}>
                            <h3 className={'text-sm font-bold'}>{!collectPersonalData ? t('unique-information') : t('identity-information')}</h3>

                            <div className={'flex items-center mt-1.5'}>
                                <MemoCoinImage bgColor={''} coinName={'Argos Logo'} logoSrc={argos}/>
                                <div>
                                    <span className={'font-bold ml-1.5'}>ARGOS ID</span>
                                    {!collectPersonalData && <span className={'font-bold ml-1.5'}>{t('personal-information-excluded')}</span>}
                                </div>
                                {
                                    argosValidationIcon()
                                }
                            </div>
                            {
                                argosValidationText()
                            }
                        </article>
                    }



                {
                    activityCoinWithAtLeastOne && <BlockChainNetworkList step={step} activityCoin={activityCoin} wallet={wallet}/>
                }

            </>

        )
    }


export default WalletContentsWrapper;
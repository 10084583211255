import React, {useCallback, useMemo} from 'react';
import {isMobile, isChrome, isFirefox, isEdge, isDesktop, isOpera} from 'react-device-detect';
import metamask from "../assets/metamask.svg";
import walletconnector from "../assets/walletconnector.svg";
import phantom from "../assets/phantom.png";
import {Moralis} from "moralis";
import NotSupportedWalletsList from "./NotSupportedWalletsList";
import {GoToChangeNetworkParam, ICurrentSelectNetwork} from "../types/type";
import cryptoWallet from '../assets/crypto-wallet.svg';

interface ISupportedWalletsList {
    walletLogin: (redirectURL: string, walletProvider?: Moralis.Web3ProviderType) => void
    phantomLogin: () => void
    currentSelectNetwork: ICurrentSelectNetwork
    goToChangeNetwork: (step: GoToChangeNetworkParam) => void
}


const SupportedWalletsList: React.FC<ISupportedWalletsList> =
    ({
         walletLogin,
         phantomLogin,
         currentSelectNetwork,
         goToChangeNetwork
     }) => {





        return (

            <>
                <article className={'grid grid-cols-3 grid-flow-col gap-3'}>
                    {
                        currentSelectNetwork.chainId !== 'sol'&& currentSelectNetwork.chainId !== 'btc' &&
                        <>
                            {
                                isDesktop && (isChrome || isEdge || isFirefox || isOpera) ?
                                    <div
                                        className={'border-solid border-2 hover:border-gray-400 border-gray-200 rounded-md flex-col text-center font-bold cursor-pointer'}
                                        onClick={() => walletLogin("https://metamask.io/download/")}>
                                        <img src={metamask} alt={'metaMask'}
                                             className={'flex-1 h-full p-3 mx-auto -mb-2.5'}
                                             style={{'height': '65px'}}/>
                                        <span className={"text-xs"}>MetaMask</span>
                                    </div>
                                    :
                                    null
                            }
                            <div className={'flex-col text-center border-solid border-2 hover:border-gray-400 border-gray-200 rounded-md font-bold leading-4 cursor-pointer'}
                                onClick={() => walletLogin("https://metamask.io/download/", 'walletconnect')}>
                                <img src={walletconnector} alt={'walletconnector'}
                                     className={'flex-1 h-full p-3 mx-auto'} style={{'height': '60px'}}/>
                                <span className={"text-xs"}>Wallet Connect</span>
                            </div>
                        </>
                    }



                </article>
                {
                    currentSelectNetwork.chainId === 'sol' ?
                        isDesktop && (isChrome || isEdge || isFirefox || isOpera) ?
                            <div className={'flex-col text-center border-solid border-2 hover:border-gray-400 border-gray-200 rounded-md font-bold cursor-pointer'}
                                 onClick={phantomLogin}
                                 style={{'width':'100px'}}
                            >
                                <img src={phantom} alt={'phantom'} className={'flex-1 h-full p-3 mx-auto -mb-2.5'}
                                     style={{'height': '65px'}}/>
                                <span className={"text-xs"}>Phantom</span>
                            </div>
                            :
                            <NotSupportedWalletsList/>
                        :
                        null
                }
                {
                    currentSelectNetwork.chainId === 'btc' ?
                        <div
                            onClick={() => goToChangeNetwork(998)}
                            className={'flex-col text-center border-solid border-2 hover:border-gray-400 border-gray-200 rounded-md font-bold cursor-pointer'} style={{'width':'100px'}}>
                            <img src={cryptoWallet} alt={'cryptoWallet'} className={'flex-1 h-full p-3 mx-auto -mb-2.5'} style={{'height': '65px'}}/>
                            <span className={"text-xs"}>Proof of Satoshi</span>
                        </div>
                        :
                        null
                }

            </>

        )



    }

export default SupportedWalletsList;
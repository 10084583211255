import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";
import cryptoWallet from '../../../assets/crypto-wallet.svg';
import {useSatoshiActions, useSatoshiStep} from "./context/SatoshiStepContext";

interface IProofOfSatoshiHeader {
    goBackWalletPage : () => void
}


const ProofOfSatoshiHeader = ({goBackWalletPage} : IProofOfSatoshiHeader) => {


    const {satoshiStepDecrease} = useSatoshiActions();
    const satoshiStep = useSatoshiStep();

    const goToBack = () => {
        if (satoshiStep === 0) return goBackWalletPage();
        return satoshiStepDecrease()
    }


    return (
        <article className={'flex items-center w-full mt-3'}>
            {
                satoshiStep !== 1 && <FontAwesomeIcon icon={faArrowCircleLeft} onClick={goToBack} className={'cursor-pointer'}/>
            }
            <div className={'flex items-center justify-center w-full'}>
                <img src={cryptoWallet} alt={"cryptoWallet"} width={'23px'} height={'auto'}/>
                <span className={'mx-1 font-bold'}>Proof Of Satoshi</span>
            </div>

        </article>
    )
}

export default React.memo(ProofOfSatoshiHeader)
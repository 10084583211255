import React, {Dispatch, SetStateAction, useContext} from 'react';
import {useMoralis} from "react-moralis";
import {IUser} from "../../types/type";
import {useTranslation} from "react-i18next";
import {ThemeContext} from "../../index";


interface ICertificationComplete  {
    argosId : string
    setUser : Dispatch<SetStateAction<IUser>>

}

const CertificationComplete: React.FC<ICertificationComplete> =
    ({
        argosId,
        setUser
     }) => {

        const {logout : moralisLogOut} = useMoralis()
        const {t} = useTranslation()
        const theme = useContext(ThemeContext);

        const logout = async () => {
            await moralisLogOut().then(() => {
                setUser({
                    objectId : "",
                    id : "",
                    className : ""
                })
            })
        }

        return (
            <main className={'h-full flex-col flex items-center justify-center py-5'}>
                <p className={'text-center my-5'}>{t('authentication-completed-1')} <br/>{t('authentication-completed-2')}</p>
                <button
                    style={{
                        backgroundColor : theme.mainColor,
                        color : theme.subColor
                    }}
                    onClick={logout}
                    className={`bg-blue-500 text-white font-bold py-2 px-12 rounded-full mx-auto block w-full`}>
                    {t('go-back-to-service')}
                </button>
            </main>
        )
    }

export default CertificationComplete;
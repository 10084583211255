import React, {CSSProperties, useContext} from 'react';
import SmallLoading from "../SmallLoading";
import {useTranslation} from "react-i18next";
import {ThemeContext} from "../../index";

interface IButton {
    onClick: any
    value?: string
    conditionalClassName: CSSProperties
    networkLoading?: boolean
}

const Button: React.FC<IButton> = ({onClick, value = 'continue', conditionalClassName , networkLoading}) => {
    const {t} = useTranslation();

    return (
        <button
            disabled={!!conditionalClassName.opacity}
            onClick={onClick}
            className={`cursor-pointer text-white h-12 font-bold py-2 px-12 rounded-full mx-auto block my-3.5 w-full`}
            style={conditionalClassName}
        >

                <span className="flex items-center justify-center ml-2" style={{color : conditionalClassName.color}}>
                    {t(value)}
                    {
                        networkLoading && <SmallLoading borderRightColor={'deepskyblue'}/>
                    }
                </span>
        </button>
    )
}
export default Button
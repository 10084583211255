import React, {Dispatch, SetStateAction} from "react";
import ProofOfSatoshiHeader from "./ProofOfSatoshiHeader";
import Satoshi1 from "./pages/Satoshi-1";
import Satoshi2 from "./pages/Satoshi-2";
import Satoshi3 from "./pages/Satoshi-3";
import Satoshi4 from "./pages/Satoshi-4";
import {useSatoshiStep} from "./context/SatoshiStepContext";
import {IWallet} from "../../../types/type";



interface IProofOfSatoshiContainer {
    goBackWalletPage : () => void;
    setWallet : Dispatch<SetStateAction<IWallet[]>>
}

const ProofOfSatoshiContainer = ({goBackWalletPage , setWallet} : IProofOfSatoshiContainer) => {


    const satoshiStep = useSatoshiStep();

    return (
        <section className={'flex flex-col items-start'}>
            <ProofOfSatoshiHeader goBackWalletPage={goBackWalletPage}/>
            {
                satoshiStep === 0 && <Satoshi1/>
            }
            {
                satoshiStep === 1 && <Satoshi2/>
            }
            {
                satoshiStep === 2 && <Satoshi3/>
            }
            {
                satoshiStep === 3 && <Satoshi4 setWallet={setWallet} goBackWalletPage={goBackWalletPage}/>
            }
        </section>
    )
}
export default ProofOfSatoshiContainer
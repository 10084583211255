import React, {useMemo , createContext} from 'react';
import ReactDOM from "react-dom/client"
import './style/tailwind-postcss-output.css'
import App from './App';
import {MoralisProvider} from "react-moralis";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import reactToWebComponent from 'react-to-webcomponent';
import './lang/i18n';
import {ITheme} from "./types/type";



Amplify.configure(awsExports);

export interface IIndex {
    project: string,
    chains: string
    buttonStyle: string
    env : "dev" | "live",
    mainTheme : string,
    subTheme : string
}

export const ThemeContext = createContext<ITheme>({
    mainColor : "",
    subColor : ""
});

const Index = ({project, chains = "", buttonStyle = "", env = "live"}: IIndex) => {


    const argosIdButtonStyle = useMemo(() => {
        if (buttonStyle.trim()) {
            try{
                return JSON.parse(buttonStyle);
            }catch (e) {
                return ''
            }
        }
        return ''
    }, []);

    const chainsProps = useMemo(() => {
        if (chains.trim()) {
            return chains.split(",");
        }
        return ''
    }, [])






    return (
        <React.StrictMode>
            <MoralisProvider
                appId={env === 'live' ? 'AFKzdV4bt9Q0svxnhF7z8M4hEoLUQLUiHkpdbHfm' : '8JjKQXcUhw1W8CPFdHGuLkK6KLAjTsNp5dZhtZcU'}
                serverUrl={env === 'live' ? 'https://3ksnkbyqox9c.usemoralis.com:2053/server' : 'https://9oomlov5gg5z.usemoralis.com:2053/server'}>
                <ThemeContext.Provider value={{
                    mainColor : '#3b82f6',
                    subColor : '#fff',
                }}>
                    <App project={project}
                         chains={chainsProps}
                         buttonStyle={argosIdButtonStyle}
                         env={env}
                    />
                </ThemeContext.Provider>

            </MoralisProvider>
        </React.StrictMode>
    )
}

const ArgosId = reactToWebComponent(Index as React.FunctionComponent<IIndex>, React as any, ReactDOM as any, {
    props: ['project', 'chains', 'buttonStyle', 'env']
});
customElements.define('argos-id', ArgosId as any)
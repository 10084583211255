import React, {
    forwardRef,
    HTMLInputTypeAttribute,
    PropsWithChildren,
    RefObject,
    useCallback,
    useRef,
    useState
} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {addressKey} from "./context/BtcAddressContext";
import {toastSuccess} from "../../../toast";

interface CommonInterface {
    classNames?: string
}

/** Span Tag **/
interface IProofOfSatoshiSpan extends CommonInterface {
    classNames?: string
}

export function ProofOfSatoshiSpan({children, classNames = ''}: PropsWithChildren<IProofOfSatoshiSpan>) {
    return <span className={`${classNames} text-sm`}>{children}</span>
}

/** Input Tag **/

interface IProofOfSatoshiInput extends CommonInterface {
    handleChange?: (key: addressKey, value: string) => void;
    value?: string
    placeholder?: string,
    validText?: string,
    validTextClassNames?: string,
    copyIcon ?: boolean
    name ?: addressKey
    label ?: string
    disabled ?: boolean
}

export function ProofOfSatoshiInput
({
     value,
     placeholder,
     handleChange,
     classNames,
     validText = "",
     validTextClassNames = "",
     copyIcon = false,
     name = "from",
     label = "",
    disabled = false
 }: IProofOfSatoshiInput) {


    const [copyText , setCopyText] = useState<'Copy'|'Copied!'>('Copy');

    const copyAddress = useCallback((value?: string) => {
        try {
            if (!value) return;
            navigator.clipboard.writeText(value);
            setCopyText('Copied!');
            setTimeout(() => {
                setCopyText('Copy');
            }, 1000);
        } catch (e: any) {
            setTimeout(() => {
                setCopyText('Copy');
            }, 1000);
        }
    }, [])

    return (
        <>
            <div className={'relative flex items-center'}>
                {
                    label && <label htmlFor={name} className={'mr-5 text-xs font-bold block'} style={{'width':'85px'}}>{label}</label>
                }
                <input
                    id={name}
                    name={name}
                    onChange={(e) => handleChange && handleChange(name as addressKey,e.currentTarget.value)}
                    value={value}
                    type="text"
                    disabled={disabled}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${classNames}`}
                    placeholder={placeholder} required/>
                {copyIcon &&
                    <>
                        <div onClick={() => copyAddress(value)}
                             className={'absolute text-gray-400 cursor-pointer text-xxxs flex items-center justify-center'}
                             style={{top: '58%', right: '0%', transform: 'translate(-50%, -50%)'}}>
                            <FontAwesomeIcon
                                className={'mr-0.5'}
                                icon={faCopy}
                            />
                            <span className={'font-bold'} style={{'color': 'inherit'}}>{copyText}</span>
                        </div>
                    </>
                }
            </div>
            {validText && <span className={`w-full block text-right text-sm mt-1 font-bold ${validTextClassNames}`}>{validText}</span>}

        </>
    )
};


/** paragraph Tag **/
interface ProofOfSatoshiParagraph extends CommonInterface {
    value?: string
    classNames?: string
}

export function ProofOfSatoshiParagraph({children, classNames}: PropsWithChildren<ProofOfSatoshiParagraph>) {
    return <p className={classNames ? `${classNames}` : ''}>{children}</p>
}



/** ProofOfSatoshiReceiver Tag **/
interface ProofOfSatoshiReceiver extends CommonInterface {}
export function ProofOfSatoshiReceiver({children, classNames = ''}: PropsWithChildren<ProofOfSatoshiReceiver>) {
    return (
        <div className={`w-full flex items-center justify-between text-sm ${classNames}`}>
            {children}
        </div>
    )
}


import {Children, isValidElement, ReactNode, useMemo} from "react";
import {
    ProofOfSatoshiInput,
    ProofOfSatoshiParagraph, ProofOfSatoshiReceiver,
    ProofOfSatoshiSpan
} from "./ProofOfSatoshiElement";
import {SATOSHI_INPUT_TYPE, SATOSHI_PARAGRAPH, SATOSHI_RECEIVER, SATOSHI_SPAN_TYPE} from "./type/ProofOfSatoshiType";


function GET_CHILDREN_TYPE(children: ReactNode, childrenType: any) {
    const childrenArray = Children.toArray(children);
    return childrenArray.filter(child => isValidElement(child) && child.type === childrenType);
}


interface IDialogMain {
    children?: ReactNode;
}


const ProofOfSatoshiCompoundComponent = ({children}: IDialogMain) => {
    const satoshiSpan = GET_CHILDREN_TYPE(children, SATOSHI_SPAN_TYPE);
    const satoshiParagraph = GET_CHILDREN_TYPE(children, SATOSHI_PARAGRAPH);
    const satoshiInput = GET_CHILDREN_TYPE(children, SATOSHI_INPUT_TYPE);
    const satoshiReceiver = GET_CHILDREN_TYPE(children, SATOSHI_RECEIVER);


    return (

            <section className={'flex flex-col py-3.5 w-full'}>
                {satoshiParagraph && satoshiParagraph}
                {satoshiSpan && satoshiSpan}
                {satoshiInput && satoshiInput}
                {satoshiReceiver && satoshiReceiver}
            </section>
    )
}


export const ProofOfSatoshiCompoundWrapper = Object.assign(ProofOfSatoshiCompoundComponent, {
    Span: ProofOfSatoshiSpan,
    Input: ProofOfSatoshiInput,
    P: ProofOfSatoshiParagraph,
    Receiver : ProofOfSatoshiReceiver
})
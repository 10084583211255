import React, {Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from 'react';
import PreAuthentication from "./ArgosIdPageComponent/PreAuthentication";
import MyPage from "./ArgosIdPageComponent/MyPage";
import DoingLiveForm from "./ArgosIdPageComponent/DoingLiveForm";
import {
    ChainId,
    IActivityCoins,
    ICertificate,
    ICurrentSelectNetwork,
    IIdStatus,
    IProjectStatus, ITheme,
    IUser,
    IWallet,
    KeyOf
} from "../types/type";
import {API} from "aws-amplify";
import {ToastContainer} from "react-toastify";
import CertificationComplete from "./ArgosIdPageComponent/CertificationComplete";
import {toastError} from "../toast";
import ConsentForm from "./ArgosIdPageComponent/ConsentForm";
import {useTranslation} from "react-i18next";
import uniqid from "uniqid";
import ProjectStatusClose from "./ArgosIdPageComponent/ProjectStatusClose";
import ModalWithChildren from "../components/common/ModalWithChildren";
import ChangeNetwork from "./ArgosIdPageComponent/ChangeNetwork";
import {ThemeContext} from "../index";
import RingsLoaderMemo from "../components/common/Loader";
import ProofOfSatoshi from "./ArgosIdPageComponent/ProofOfSatoshi/ProofOfSatoshi";
import {useProjectOptionAction, useProjectOptionValue} from "../context/ArgosIdOption";
import {COIN_KEY} from "../constant";


interface IArgosId {
    user: IUser
    setUser: Dispatch<SetStateAction<IUser>>
    alreadyUserId: boolean
    firstVisit: boolean
    setFirstVisit: Dispatch<SetStateAction<boolean>>
    projectId: string
    chains: string | string[]
    env: "live" | "dev"

}

const ArgosId: React.FC<IArgosId> =
    ({
         user,
         setUser,
         alreadyUserId,
         firstVisit,
         setFirstVisit,
         projectId,
         chains,
         env,

     }) => {

        const theme = useContext(ThemeContext);

        const [step, setStep] = useState<number>(0);
        const [nextPageAnimation, setNextPageAnimation] = useState<boolean>(false);
        const [prevPageAnimation, setPrevPageAnimation] = useState<boolean>(false);
        const [liveFormResult, setLiveFormResult] = useState<IIdStatus | null>(null);
        const [activityCoin, setActivityCoin] = useState<IActivityCoins | null>(null);
        const [wallet, setWallet] = useState<IWallet[]>([]);
        const [loading, setLoading] = useState<boolean>(true);
        const [error, setError] = useState<boolean>(false);
        const [logo, setLogo] = useState<string>('');
        const [projectStatus, setProjectStatus] = useState<IProjectStatus>('closed');
        const [userEmail, setUserEmail] = useState<string>('');
        const [isClosed, setIsClosed] = useState<boolean>(false);
        const [collectPersonalData,setCollectPersonalData] = useState<boolean>(true);
        const [currentSelectNetwork, setCurrentSelectNetwork] = useState<ICurrentSelectNetwork>({
            chainId: "",
            name: "",
            logo: null,
            networkUrl: "",
            symbol : "",
            blockExplorerUrls : "",
            nativeCurrency : {
                name : "",
                symbol : "",
                decimals : 18
            },
            shortName : ""
        });



        const {t} = useTranslation();
        const {toggleOption} = useProjectOptionAction();
        const {optionArgosId} = useProjectOptionValue();




        useEffect(() => {
            (async () => {
                try {
                    window.localStorage.removeItem('aid');
                    window.localStorage.removeItem('consentId');
                    const res: any = await fetchingCertificateAndProject();
                    const findError = res.find((responseObject: any) => responseObject.statusCode !== 200);
                    if (findError) {
                        toastError(t("fail-to-initial-data"))
                        return setError(true);
                    }

                    if (!res) {
                        return toastError(t("fail-to-initial-data"))
                    }

                    const [certificate, project, wallets] = res;

                    if (res) {
                        const {message, statusCode, idStatus, argosId}: ICertificate = certificate
                        if (!message && statusCode === 200) {
                            setLiveFormResult(idStatus)
                            if (argosId) {
                                setUser((prev) => ({...prev , argosId}))
                            }
                        }
                        const {
                            option_bscAddress,
                            option_ethAddress,
                            option_klayAddress,
                            option_maticAddress,
                            option_solAddress,
                            option_avaxAddress,
                            option_croAddress,
                            option_etcAddress,
                            option_ftmAddress,
                            option_htAddress,
                            option_oneAddress,
                            option_opAddress,
                            option_arbAddress,
                            logoURL,
                            projectStatus,
                            option_collectPersonalData,
                            option_argosId,
                        } = project;
                        if ('string' !== typeof chains) {
                            (Object.keys(COIN_KEY) as Array<keyof typeof COIN_KEY>).forEach((keyName) => {
                                return chains.forEach((ticker) => {
                                    if (keyName.includes(ticker)) {
                                        return COIN_KEY[keyName] = true
                                    }
                                })
                            });
                            setActivityCoin(COIN_KEY)
                        } else {
                            setActivityCoin({
                                option_bscAddress,
                                option_ethAddress,
                                option_klayAddress,
                                option_maticAddress,
                                option_solAddress,
                                option_avaxAddress,
                                option_croAddress,
                                option_etcAddress,
                                option_ftmAddress,
                                option_htAddress,
                                option_oneAddress,
                                option_opAddress,
                                option_arbAddress
                            });
                        }

                        if (typeof option_argosId === 'boolean' && !option_argosId) {
                            toggleOption("optionArgosId", false)
                        }
                        setLogo(logoURL);
                        setProjectStatus(projectStatus);
                        setUserEmail(res[1].email);
                        typeof option_collectPersonalData === 'boolean' && !option_collectPersonalData && setCollectPersonalData(false);
                        res[1].projectStatus === 'closed' && setIsClosed(true);


                        if (res[1].mainColor) theme.mainColor = res[1].mainColor
                        if (res[1].subColor) theme.subColor = res[1].subColor

                        if (res[2]) {
                            const resWalletData: IWallet[] = res[2].Items.map((wallet: any) => {
                                return {
                                    address: wallet.address,
                                    chainId: wallet.chainId,
                                    walletName: wallet.name,
                                    coinTicker: wallet.coinTicker,
                                    coinName: wallet.coinName,
                                    id: wallet.id
                                }
                            });
                            setWallet(resWalletData)
                        }
                    }
                } catch (e) {
                    setError(true);
                    return toastError(t("something-is-wrong"));
                } finally {
                    setLoading(false);
                }
            })()
        }, []);


        const fetchingCertificateAndProject = useCallback(async () => {
            try {
                const [certificate, project, wallets] = await Promise.all([
                    API.get('apiArgosID', `/certificate?alias=${env}&userId=${user.id}`, {}),
                    API.get('apiArgosID', `/project?alias=${env}&projectId=${projectId}`, {}),
                    API.post('apiArgosID', '/wallet', {
                        body: {
                            userId: user.id,
                            alias: env,
                            requestType: 'get',
                        }
                    })
                ])
                return [certificate, project, wallets]
            } catch (e) {
                setError(true);
                return toastError(t('something-is-wrong'))
            }
        }, [user])


        const animationTrigger = useCallback(() => {
            setNextPageAnimation(false);
            setPrevPageAnimation(false);
        }, []);


        const openLiveForm = () => {
            const socketUid = uniqid();
            const liveFormURL = env === 'live' ? "https://form.argoskyc.com/" : "https://dev.d21afmipicwyox.amplifyapp.com/"
            const kycProjectId = env === 'live' ? 'nlrco4vxga' : '3yrf8mf8o6';

            const win = window.open(`${liveFormURL}index?pid=${kycProjectId}&aiduserId=${user.id}&aidProjectId=${projectId}&uid=${socketUid}`, 'Secure Payment');
            setLoading(true);
            const timer = setInterval(async () => {
                if (win && win.closed) {
                    clearInterval(timer);
                    try {
                        const {
                            idStatus,
                            argosId
                        }: ICertificate = await API.get('apiArgosID', `/certificate?alias=${env}&userId=${user.id}`, {});

                        if (argosId) setUser(prev => ({...prev , argosId}));
                        if (!idStatus) return setLiveFormResult(IIdStatus.notApproved)
                        setLiveFormResult(idStatus);
                        setPrevPageAnimation(true);
                        return setStep(prev => prev - 1)
                    } catch (e) {
                        console.log("Catch the Error open Live form ::: ", e)
                    } finally {
                        setLoading(false)
                    }
                }
            }, 50);
            return () => clearInterval(timer)
        }




        const goNextPage = async (allTheCoinsAreCertified: boolean = false, forceStep ?: number) => {

            if (error) return toastError(t('something-is-wrong'));

            if (liveFormResult === IIdStatus.pending){
                if (!optionArgosId) {
                    setNextPageAnimation(true);
                    if (typeof forceStep === 'number') return setStep(forceStep)
                    if (!allTheCoinsAreCertified) return setStep(2)
                    if (allTheCoinsAreCertified) return setStep(3)
                }
                return
            }
            setNextPageAnimation(true);




            if (forceStep === 0 || forceStep) return setStep(forceStep)
            if ((liveFormResult === IIdStatus.notApproved || liveFormResult === IIdStatus.rejected) && optionArgosId) {
                if (!optionArgosId) return setStep(2);
                setStep(1);
                return openLiveForm()
            }

            if ((liveFormResult === IIdStatus.approved || !optionArgosId) && !allTheCoinsAreCertified) {
                return setStep(2)
            }
            if ((liveFormResult === IIdStatus.approved || !optionArgosId) && allTheCoinsAreCertified) {
                return setStep(3);
            }
            return setStep((prev) => prev + 1)
        }





        if (isClosed) {
            return (
                <ModalWithChildren
                    setUser={setUser}
                    logo={logo}
                    animationTrigger={animationTrigger}
                    nextPageAnimation={nextPageAnimation}
                    prevPageAnimation={prevPageAnimation}
                    loading={loading}
                >
                    <ProjectStatusClose
                        userEmail={userEmail}
                    />
                    <ToastContainer limit={1}/>
                </ModalWithChildren>
            )
        }

        if (step === 999) {
            return (
                <ModalWithChildren
                    setUser={setUser}
                    logo={logo}
                    animationTrigger={animationTrigger}
                    nextPageAnimation={nextPageAnimation}
                    prevPageAnimation={prevPageAnimation}
                    loading={loading}
                >
                    <ChangeNetwork
                        setPrevPageAnimation={setPrevPageAnimation}
                        setStep={setStep}
                        currentSelectNetwork={currentSelectNetwork}
                        setCurrentSelectNetwork={setCurrentSelectNetwork}
                    />
                </ModalWithChildren>
            )
        }


        if (step === 998) {
            return (
                <ModalWithChildren
                    setUser={setUser}
                    logo={logo}
                    animationTrigger={animationTrigger}
                    nextPageAnimation={nextPageAnimation}
                    prevPageAnimation={prevPageAnimation}
                    loading={loading}
                >
                    <ProofOfSatoshi
                        setStep={setStep}
                        setPrevPageAnimation={setPrevPageAnimation}
                        setWallet={setWallet}
                    />
                </ModalWithChildren>
            )
        }



        return (
            <ModalWithChildren
                setUser={setUser}
                logo={logo}
                animationTrigger={animationTrigger}
                nextPageAnimation={nextPageAnimation}
                prevPageAnimation={prevPageAnimation}
                loading={loading}

            >
                {
                    (firstVisit && step === 0) &&
                    <ConsentForm
                        user={user}
                        setStep={setStep}
                        setFirstVisit={setFirstVisit}
                        env={env}
                    />
                }
                {
                    step === 0 && !firstVisit &&
                    <PreAuthentication
                        step={step}
                        setStep={setStep}
                        activityCoin={activityCoin}
                        loading={loading}
                        goNextStep={goNextPage}
                        liveFormResult={liveFormResult}
                        wallet={wallet}
                        user={user}
                        projectId={projectId}
                        projectStatus={projectStatus}
                        chains={chains}
                        env={env}
                        collectPersonalData={collectPersonalData}
                    />
                }
                {
                    step === 1 &&
                    <DoingLiveForm
                        goNextStep={goNextPage}
                        liveFormResult={liveFormResult}
                        loading={loading}
                    />
                }

                {
                    step === 2 && (liveFormResult === IIdStatus.approved || !optionArgosId) &&
                    <MyPage goNextStep={goNextPage}
                            activityCoin={activityCoin}
                            liveFormResult={liveFormResult}
                            step={step}
                            setStep={setStep}
                            alreadyUserId={alreadyUserId}
                            loading={loading}
                            user={user}
                            wallet={wallet}
                            setWallet={setWallet}
                            setNextPageAnimation={setNextPageAnimation}
                            currentSelectNetwork={currentSelectNetwork}
                            setCurrentSelectNetwork={setCurrentSelectNetwork}
                            env={env}
                            collectPersonalData={collectPersonalData}
                    />
                }
                {
                    step === 3 && (liveFormResult === 'approved' || !optionArgosId) &&
                    <CertificationComplete
                        setUser={setUser}
                        argosId={user.argosId as string}
                    />
                }
                <ToastContainer limit={1}/>
            </ModalWithChildren>
        )
    }
export default ArgosId
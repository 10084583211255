import React, {memo, useCallback, useMemo} from 'react';
import bsc from "../assets/binance.svg";
import eth from "../assets/ethereum.svg";
import sol from "../assets/solana.svg";
import klay from "../assets/klaytn.svg";
import matic from "../assets/polygon.svg";
import avax from '../assets/avalanche.svg';
import arb from '../assets/arbitrum.svg';
import op from '../assets/optimism.svg';
import ftm from '../assets/fantom.svg';
import cro from '../assets/cronos.png';
import one from '../assets/harmony.svg';
import ht from '../assets/huobi.svg';
import MemoCoinImage from "./CoinImage";
import {IActivityCoins, IWallet} from "../types/type";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCircleXmark , faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import {COIN_TICKER_AND_FULL_NAME_LIST} from "../constant";
import { replaceProjectCoinTicker} from "../util";


interface IEachWalletContents {
    coinKey: keyof IActivityCoins
    activityCoin: IActivityCoins
    step?: number,
    wallet?: IWallet[] | undefined
    showBlockChainList ?: boolean
}


const EachCoinArticle: React.FC<IEachWalletContents> =
    ({
         coinKey,
         activityCoin,
         step,
         wallet,
         showBlockChainList= false
     }) => {







        const selectLogoSrc = useCallback(() => {
            const logo : {[key : string] : any} = {
                'bsc': bsc,
                'eth': eth,
                'sol': sol,
                'klay': klay,
                'matic': matic,
                'avax': avax,
                'arb': arb,
                'op' : op,
                'ftm' : ftm,
                'cro' : cro,
                'one' : one,
                'etc' : eth,
                'ht' : ht
            }
            return logo[replaceCoinTicker]
        },[])


        const checkCoinCertificatedReturnIconOrBoolean = (selectCoinTicker: string, type: "string" | "boolean" = "string"): React.ReactNode | boolean => {



            const coinTicker = replaceProjectCoinTicker(selectCoinTicker)
            if (!wallet) {
                if (type === 'boolean') {
                    return false;
                }
                return <FontAwesomeIcon icon={faCircleXmark} className={'ml-1.5 text-rose-600'}/>
            }

            const certificatedWallet = wallet.find((item) => coinTicker === item.coinTicker);

            if (!certificatedWallet) {
                if (type === 'boolean') {
                    return false;
                }
                return <FontAwesomeIcon icon={faCircleXmark} className={`ml-1.5 text-rose-500  ${step === 2 && ` absolute top-0`}`} style={{zIndex: '30', left: '-9px'}}/>
            }
            if (coinTicker === selectCoinTicker) {
                if (type === 'boolean') {
                    return true;
                }
                return <FontAwesomeIcon icon={faCircleCheck} className={`ml-1.5 text-blue-500  ${step === 2 && ` absolute top-0`}`} style={{zIndex: '30', left: '-9px'}}/>
            }
        }





        const replaceCoinTicker = useMemo(() => {
            return replaceProjectCoinTicker(coinKey)
        },[coinKey]);


        const selectCoin = useMemo(() => {
            return COIN_TICKER_AND_FULL_NAME_LIST.find((item) => item.ticker === replaceCoinTicker);
        },[replaceCoinTicker])


        if (activityCoin[coinKey] && selectCoin) {
            return (
                <li className={`relative flex items-center mt-2.5 ${step === 2 && ' flex-col'} ${showBlockChainList && checkCoinCertificatedReturnIconOrBoolean(selectCoin.ticker, 'boolean') && 'hidden'}`}>
                    {step === 2 && checkCoinCertificatedReturnIconOrBoolean(selectCoin.ticker)}
                    <MemoCoinImage bgColor={`${replaceCoinTicker}-bg-color`}
                                   coinName={replaceCoinTicker}
                                   logoSrc={selectLogoSrc()}
                    />



                    {step === 0 ?
                        <span className={'font-bold ml-1.5'}>
                            {selectCoin.fullName}
                            {checkCoinCertificatedReturnIconOrBoolean(selectCoin.ticker)}
                        </span>  :
                        <span
                            className={`uppercase font-bold mt-1 text-xs 
                            ${checkCoinCertificatedReturnIconOrBoolean(selectCoin.ticker, "boolean") ? 'text-blue-500' : 'text-rose-500'}`}>
                            {selectCoin.ticker}
                        </span>
                    }
                </li>
            )
        }
        return null
    }
export default memo(EachCoinArticle);
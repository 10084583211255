import React, {useContext} from 'react';
import {ThemeContext} from "../../../../index";
import {useBtcAddressAction, useBtcAddressValue} from "../context/BtcAddressContext";
import {ProofOfSatoshiCompoundWrapper} from "../ProofOfSatoshiCompoundWrapper";
import Button from "../../../../components/common/Button";
import {useSatoshiActions} from "../context/SatoshiStepContext";






const Satoshi3 = () => {
    const theme = useContext(ThemeContext);
    const inputValue = useBtcAddressValue();
    const {handleText} = useBtcAddressAction();
    const {satoshiStepIncrease} = useSatoshiActions();


    return (
        <>
            <ProofOfSatoshiCompoundWrapper>
                <ProofOfSatoshiCompoundWrapper.P classNames={'mt-2 text-xs'}>
                    Check information to send BTC from <span className={'text-blue-600 font-bold'}>{inputValue.get('from')}</span>
                </ProofOfSatoshiCompoundWrapper.P>

                <ProofOfSatoshiCompoundWrapper.Input
                    value={inputValue.get('to')}
                    label={'Destination address'}
                    name={"to"}
                    handleChange={handleText}
                    placeholder={'Destination address'}
                    classNames={'mt-2'}
                    copyIcon={true}
                    disabled={true}
                />

                <ProofOfSatoshiCompoundWrapper.Input
                    value={inputValue.get('btcAmount')}
                    label={'BTC' + '\n' + 'amount'}
                    name={"btcAmount"}
                    handleChange={handleText}
                    placeholder={'BTC Wallet Address'}
                    classNames={'mt-2'}
                    copyIcon={true}
                    disabled={true}
                    validText={'Be careful to send your BTC. It must be matched exactly.'}
                    validTextClassNames={'text-rose-600 text-xs'}
                />
            </ProofOfSatoshiCompoundWrapper>
            <Button onClick={satoshiStepIncrease}
                    conditionalClassName={{
                        backgroundColor: theme.mainColor,
                        color: theme.subColor,
                        margin: 0
                    }}
            />
        </>

    )
}

export default Satoshi3
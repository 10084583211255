import React, {Dispatch, memo, SetStateAction, useContext, useEffect, useMemo} from 'react';
import argos from '../../assets/argos.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons'
import {useMoralis} from "react-moralis";
import {IUser} from "../../types/type";
import {ThemeContext} from "../../index";
import {transform} from "terser-webpack-plugin/types/minify";


interface IHeader {
    setUser : Dispatch<SetStateAction<IUser>>
    logo : string
}

const Header: React.FC<IHeader> = ({setUser , logo}) => {

    const {logout : moralisLogOut , isAuthenticated} = useMoralis();
    const theme = useContext(ThemeContext);



    const logout = async () => {
        await moralisLogOut().then(() => {
            setUser({
                objectId : "",
                id : "",
                className : ""
            })
        })
    }

    const headerImg : string = useMemo(() => {
        return logo ? logo : argos
    },[logo])


    return (
        <section className={'flex flex-row-reverse justify-between px-3 py-4 w-full relative'}
            style={{backgroundColor : theme.mainColor}}
        >
            <div className={'p-4 border-white border-4 shadow-sm bg-white w-16 h-16 rounded-full absolute left-1/2 top-4/10'}
                 style={{
                     transform: 'translate3D(-50%,0,0)',
                     zIndex : 3,

                 }}>
                <img src={headerImg} alt={'argos-id'} style={{
                    'maxHeight': '15px',
                    'transform': 'scale(2.7)',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'margin': 'auto'
                }}/>
            </div>
            <FontAwesomeIcon onClick={logout} icon={faCircleXmark} className={'ml-1.5 text-white cursor-pointer'} style={{color : theme.subColor}}/>
        </section>
    )
}

export default memo(Header)
import React, {Dispatch, SetStateAction, useCallback, useContext, useState} from 'react';
import {ThemeContext} from "../../../../index";
import {useBtcAddressAction, useBtcAddressValue} from "../context/BtcAddressContext";
import {ProofOfSatoshiCompoundWrapper} from "../ProofOfSatoshiCompoundWrapper";
import Button from "../../../../components/common/Button";
import {useSatoshiActions} from "../context/SatoshiStepContext";
import {IWallet} from "../../../../types/type";
import {toastSuccess} from "../../../../toast";
import {useTranslation} from "react-i18next";



interface ISatoshi4 {
    setWallet : Dispatch<SetStateAction<IWallet[]>>
    goBackWalletPage : () => void;
}

const Satoshi4 = ({setWallet , goBackWalletPage} : ISatoshi4) => {
    const theme = useContext(ThemeContext);
    const inputValue = useBtcAddressValue();
    const {handleText} = useBtcAddressAction();
    const [flag , setFlag] = useState<boolean>(false);

    const {t} = useTranslation()

    const confirmBitCoin = () => {
        try {
            setFlag(true);
            if (flag) return
            setWallet((prev) => [...prev , {
                walletName : 'satoshi',
                chainId : 'btc',
                address : inputValue.get('from'),
                coinName : 'bitcoin',
                coinTicker : 'bitcoin',
            }])

            setTimeout(() => {
                goBackWalletPage()
            },1000)


            return setTimeout(() => {
                setFlag(false)
                return toastSuccess(`${t('update-success')}`);
            },1000)

        } catch (e) {
            setFlag(false)
        }
    }

    const validationTXIDtoBoolean : boolean = inputValue.get('txtAddress').length >= 13;


    return (
        <>
            <ProofOfSatoshiCompoundWrapper>
                <ProofOfSatoshiCompoundWrapper.P classNames={'mt-2 font-bold'}>
                    Input TXID you sent
                </ProofOfSatoshiCompoundWrapper.P>
                <ProofOfSatoshiCompoundWrapper.Input
                    name={"txtAddress"}
                    handleChange={handleText}
                    placeholder={'TXID Address'}
                    classNames={'mt-2'}
                    validText={validationTXIDtoBoolean ? "Valid TXID" : inputValue.get('txtAddress').length >= 1 ? "Invalid TXID" : ""}
                    validTextClassNames={validationTXIDtoBoolean ? "text-blue-500" : "text-rose-500"}
                />
                <ProofOfSatoshiCompoundWrapper.Receiver classNames={'mt-1.5'}>
                    From : {inputValue.get('from')}
                </ProofOfSatoshiCompoundWrapper.Receiver>
                <ProofOfSatoshiCompoundWrapper.Receiver classNames={''}>
                    To : {inputValue.get('to')}
                    <span className={'font-bold rounded-xl border-2 p-1 px-1.5'}>{inputValue.get('btcAmount')} BTC</span>
                </ProofOfSatoshiCompoundWrapper.Receiver>
            </ProofOfSatoshiCompoundWrapper>
            <Button onClick={confirmBitCoin}
                    value={"확인"}
                    conditionalClassName={
                        validationTXIDtoBoolean ?
                            {
                                backgroundColor: theme.mainColor,
                                color: theme.subColor,
                                margin: 0
                            } :
                            {
                                backgroundColor: theme.mainColor,
                                color: theme.subColor,
                                opacity: 0.5,
                                margin: 0
                            }
                    }
            />
        </>
    )

}


export default Satoshi4
import React, {useState, useCallback, useEffect, Dispatch, SetStateAction, useContext} from 'react';
import {IUser} from "../../types/type";
import {API} from "aws-amplify";
import {toastError} from "../../toast";
import {useTranslation} from "react-i18next";
import {ThemeContext} from "../../index";





interface IConsentForm {
    setStep: Dispatch<SetStateAction<number>>
    setFirstVisit: Dispatch<SetStateAction<boolean>>
    user : IUser
    env : "dev" | "live"
}


const ConsentForm: React.FC<IConsentForm> = ({setStep, setFirstVisit, user, env}) => {


    const {t} = useTranslation();
    const theme = useContext(ThemeContext)


    const [checkList , setCheckList] = useState([
        {title : t("terms-of-use") ,required: true , checked : false, url : "https://argos.notion.site/Terms-of-Service-46a23f39bce9449e91ac690166b4ebb0"},
        {title : t("privacy-policy"),required: true , checked: false, url : "https://argos.notion.site/Privacy-Policy-575349918b42491b9002c1a98b6aaa5c"},
        {title : t("receive-advertising-information"), required: false , checked: false, url: "https://argos.notion.site/Consent-to-use-of-personal-information-and-receiving-advertisements-dbab9d818622413b800e8be313776812"},
    ]);
    const [loading , setLoading] = useState<boolean>(false);



    const [allChecked , setIsAllChecked] = useState<boolean>(false);
    const [allRequiredChecked , setIsAllRequiredChecked] = useState<boolean>(false);


    const handleCheck = (e : React.ChangeEvent<HTMLInputElement> , index : number) => {
        const newCheckList = checkList.map((listItem , listItemIndex) => {
            if (listItemIndex === index) {
                return {
                    ...listItem,
                    checked : e.target.checked
                }
            }
            return listItem
        });
        setCheckList(newCheckList)
    }

    // 체크박스 전체 선택
    const handleAllCheck = () => {
        const newCheckList = checkList.map((listItem) => {
            return {
                ...listItem,
                checked : allChecked ? false : true
            }
        });
        setCheckList(newCheckList);
    };

    // 버튼 클릭 시 checkList 전송
    const btnClickHandler = useCallback(async () => {
        try{
            setLoading(true)
            await API.post('apiArgosID',`/user?alias=${env}&objectId=${user.objectId}&userId=${user.id}&requestType=setUserId`,{
                body : {
                    consentMarketing: checkList[2].checked
                }
            });
            setFirstVisit(false)
            setStep(0)

        }catch (e) {
            return toastError(t('something-is-wrong'))
        } finally {
            setLoading(false)
        }
    },[checkList[2].checked])


    useEffect(() => {
        const res = checkList.every(listItem => listItem.checked);
        if (res) {
            return setIsAllChecked(true);
        }
        return setIsAllChecked(false)
    },[checkList])


    useEffect(() => {
        const requiredCheck = checkList.every(listItem => listItem.checked || !listItem.required);
        if (requiredCheck) {
            return setIsAllRequiredChecked(false);
        }
        return setIsAllRequiredChecked(true);
    },[checkList])


    return (
        <section className={'flex flex-col'}>

            <p className={'text-center text-xl mt-1.5'}>{t("you-are-new-to-argos")}</p>

            <div className="flex items-center mt-7 mb-7 ">
                <input id="default-checkbox" type="checkbox" checked={allChecked}  onChange={handleAllCheck}
                       className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="default-checkbox" className="ml-2 text-sm font-bold text-gray-900 dark:text-black-300">
                    {t('agree-to-all-below')}
                </label>
            </div>

              {checkList.map((item , index) => {
                return (
                    <div className={'flex items-center my-2'} key={item.title}>
                        <input id={item.title} type={'checkbox'} className={'h-4 w-4'} checked={item.checked} onChange={e => handleCheck(e , index)}/>
                        <article className={'flex justify-between items-center w-full'}>
                            <div className={'flex flex-row items-center'}>
                                <label htmlFor={item.title} className={'ml-2 underline underline-offset-4 text-sm'}>{item.title}</label>
                                <span className={'ml-2 text-xxxs font-thin'}>{item.required ? t('required-2') : t('option-2')}</span>
                            </div>
                            <a href={item.url} rel={"noreferrer"} target="_blank" className={'ml-2 mt-1 text-[2px] text-gray-600 justify-between'}>{t('detail')} 〉</a>
                        </article>
                    </div>
                )
            }
                )}
            <button type="button"
                    disabled={allRequiredChecked || !(!loading)}
                    onClick={btnClickHandler}
                    className="disabled:cursor-not-allowed disabled:hover mt-5 font-bold rounded-lg text-sm px-5 py-2.5 text-center"
                    style={!allRequiredChecked || !(!loading) ? {backgroundColor : theme.mainColor , color : theme.subColor} : {backgroundColor : theme.mainColor , color : theme.subColor , opacity : 0.5}}
            >
                {t('continue')}
            </button>

        </section>
    );
}

export default ConsentForm;
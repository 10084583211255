import React, {Dispatch, PropsWithChildren, ReactNode, SetStateAction} from 'react';
import Header from "./Header";
import Modal from "react-modal";
import {IUser} from "../../types/type";


const customStyles = {
    content: {
        top: '50%',
        width: '100%',
        height: '100%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "rgba(0,0,0,0.6)",
        display: 'flex',
        alignItems : 'center',
        justifyContent : 'center'
    },
};

interface IModalWithChildren {
    setUser: Dispatch<SetStateAction<IUser>>
    logo: string
    animationTrigger: () => void;
    nextPageAnimation: boolean,
    prevPageAnimation: boolean,
    loading: boolean

}


const ModalWithChildren: React.FC<React.PropsWithChildren<IModalWithChildren>> =
    ({
         children,
         setUser,
         logo,
         animationTrigger,
         nextPageAnimation,
         prevPageAnimation,
         loading,

     }) => {
        return (
            <Modal
                portalClassName={'argos-id-modal'}
                isOpen={true}
                style={customStyles}
                contentLabel="Argos ID Modal"
                ariaHideApp={false}
            >
                <main id={'modal-wrapper'} className={'mx-auto rounded-md bg-white overflow-hidden'} style={{'width': '425px'}}>
                    {
                        loading ? null : <Header setUser={setUser} logo={logo}/>
                    }

                    <section
                    onAnimationEnd={animationTrigger}
                    className={`w-full p-3 will-change-transform
                    ${nextPageAnimation ? ' wrapper-slide-in' : ''}
                    ${prevPageAnimation ? ' wrapper-slide-out' : ''}
                    ${!loading ? ' mt-4' : ''}
                    `}>
                        {children}
                    </section>
                </main>
            </Modal>
        )
    };
export default ModalWithChildren
import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import SignIn from "./pages/SignIn";
import ArgosId from "./pages/ArgosId";
import {ITheme, IUser} from "./types/type";
import i18n from "i18next";
import {OptionArgosIdProvider} from "./context/ArgosIdOption";

interface IApp {
    project : string
    chains : string[] | string
    buttonStyle : object | string
    env : "live" | "dev"

}


const App: React.FC<IApp> =
    ({
         project,
         chains,
         buttonStyle,
         env,

     }) => {


    useLayoutEffect(() => {
        const locale = navigator.language;
        i18n.changeLanguage('en-US')
        if (locale === 'ko-KR') {
            i18n.changeLanguage(locale)
        } else if (locale === 'en-US') {
            i18n.changeLanguage(locale)
        }
    }, [])


    const projectId = useMemo(() => project,[]);


    const [user, setUser] = useState<IUser>({
        objectId : "",
        id : "",
        className : "",
    })
    const [alreadyUserId , setAlreadyUserId] = useState<boolean>(false);
    const [firstVisit , setFirstVisit] = useState<boolean>(false);





    if (user.objectId && user.id) {
        return (
            <OptionArgosIdProvider>
                <ArgosId
                    alreadyUserId={alreadyUserId}
                    user={user}
                    setUser={setUser}
                    firstVisit={firstVisit}
                    setFirstVisit={setFirstVisit}
                    projectId={projectId}
                    chains={chains}
                    env={env}

                />
            </OptionArgosIdProvider>
        )
    }

    return (
        <SignIn
            setAlreadyUserId={setAlreadyUserId}
            setUser={setUser}
            setFirstVisit={setFirstVisit}
            buttonStyle={buttonStyle}
            env={env}
        />
    )


}


export default App;
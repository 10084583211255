import React, {createContext, PropsWithChildren, useState, useMemo, useContext} from 'react';


interface IBtcAddressContextAction {
    handleText: (key: addressKey, value: string) => void
}

export type addressKey = 'from' | 'to' | 'txtAddress' | 'btcAmount'

const AddressValueContext = createContext<any>(null);
const AddressActionContext = createContext<IBtcAddressContextAction | null>(null);


export function BtcAddressProvider({children}: PropsWithChildren) {
    const [address, setAddress] = useState(new Map<addressKey, string>([
        ['from', ''],
        ['to', 'asdfasdfasdfasdf'],
        ['txtAddress', ''],
        ['btcAmount', '0.000000037']
    ]));





    const actions: IBtcAddressContextAction = useMemo(() => ({
        handleText(key: addressKey, value) {
            setAddress((prev) => new Map(prev).set(key, value));
        },
    }), []);

    return (
        <AddressActionContext.Provider value={actions}>
            <AddressValueContext.Provider value={address}>
                {children}
            </AddressValueContext.Provider>
        </AddressActionContext.Provider>
    )
}

export function useBtcAddressValue() {
    const value = useContext(AddressValueContext)
    if (!value) throw new Error('useBtcAddressValue should be used within BtcAddressProvider');
    return value
}

export function useBtcAddressAction() {
    const actions = useContext(AddressActionContext);
    if (!actions) throw new Error('useBtcAddressAction should be used within BtcAddressProvider');
    return actions
}
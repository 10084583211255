import React, {useState, useEffect, useMemo, Dispatch, SetStateAction, useCallback,} from 'react';
import {useMoralis} from "react-moralis";
import phantom from '../../assets/phantom.png';
import {ICommonPage} from "../../types/commonModal";
import {
    GoToChangeNetworkParam,
    IAcceptCoinTable,
    IActivityCoins,
    ICurrentSelectNetwork,
    IIdStatus,
    IUser,
    IWallet
} from "../../types/type";
import MoralisType, {Moralis} from "moralis";
import WalletContentsWrapper from "../../components/common/WalletContentsWrapper";
import {API} from "aws-amplify";
import RingsLoaderMemo from "../../components/common/Loader";
import {COIN_TICKER_AND_FULL_NAME_LIST} from "../../constant";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WalletList from "../../components/WalletList";
import {faWallet} from "@fortawesome/free-solid-svg-icons";
import {replaceProjectCoinTicker} from "../../util";
import {toastError, toastInfo, toastSuccess} from "../../toast";
import SmallLoading from "../../components/SmallLoading";
import SupportedWalletsList from "../../components/SupportedWalletsList";
import {useTranslation} from "react-i18next";
import VerifyPersonWallet from "../../components/VerifyPersonWallet";
import {useProjectOptionValue} from "../../context/ArgosIdOption";



interface IMyPage extends ICommonPage {
    goNextStep: (param ?: boolean, forceStep ?: number) => void,
    activityCoin: IActivityCoins | null,
    liveFormResult: IIdStatus | null
    step: number
    alreadyUserId: boolean
    user: IUser
    loading: boolean,
    wallet: IWallet[]
    setWallet: React.Dispatch<React.SetStateAction<IWallet[]>>
    setStep : Dispatch<SetStateAction<number>>
    setNextPageAnimation : Dispatch<SetStateAction<boolean>>
    currentSelectNetwork : ICurrentSelectNetwork
    setCurrentSelectNetwork : Dispatch<SetStateAction<ICurrentSelectNetwork>>
    env : "dev" | "live"
    collectPersonalData : boolean
}





const MyPage: React.FC<IMyPage> =
    ({
         goNextStep,
         activityCoin,
         liveFormResult,
         step,
         alreadyUserId,
         user,
         loading,
         wallet,
         setWallet,
         setStep,
         setNextPageAnimation,
        currentSelectNetwork,
        setCurrentSelectNetwork,
        env,
         collectPersonalData
     }) => {


        const {authenticate, Moralis } = useMoralis();
        const {t} = useTranslation()
        const [walletLoading, setWalletLoading] = useState<boolean>(false);
        const [flag, setFlag] = useState<boolean>(false);
        const {optionArgosId} = useProjectOptionValue()

        useEffect(() => {
            if (!activityCoin) return;

            const projectCoinKey = (Object.keys(activityCoin) as (keyof typeof activityCoin)[])
                .filter((coinKey) => activityCoin[coinKey])
                .map((coinKey) => replaceProjectCoinTicker(coinKey));

            const AllTheCoinsAreCertified: boolean = projectCoinKey.every((key: string) => !!wallet.find((wallet) => wallet.coinTicker === key));
            if (AllTheCoinsAreCertified && (liveFormResult === 'approved' || !optionArgosId)) {
                goNextStep(undefined, 0)
            }
        }, [wallet.length])





        const AcceptCoinTableList: IAcceptCoinTable[] = useMemo(() => {
            return COIN_TICKER_AND_FULL_NAME_LIST
        }, [])


        const walletDuplicationVerification = (chainId: string, walletAddress: string): IWallet | undefined => {
            return wallet.find((eachWallet) => eachWallet.chainId === chainId && eachWallet.address === walletAddress);
        }


        const updateWalletInfo = async (walletAddress: string, walletName: string) => {
            let chainId: string | number
            const hexChainId : any = await Moralis.getChainId();
            chainId = (parseInt(hexChainId)) + '';
            if (walletName === 'phantom') {
                chainId = 'sol'
            }

            if (walletDuplicationVerification(chainId, walletAddress)) {
                /**ArgosID 에서 중복 입니다 다시 선택해주세요 **/
                return toastError(`${t('wallet-already-exist')}`);
            }

            const coinName = AcceptCoinTableList.find((item) => item.network === chainId);
            if (!coinName) {
                /**ArgosID 에서 지원하지 않는 코인 입니다 다시 선택해주세요 **/
                return toastError(`${t('coin-is-not-supported')}`);
            }
            const selectCoinTickersAndFullName = AcceptCoinTableList.find((item) => item.fullName === coinName.fullName);
            if (!selectCoinTickersAndFullName) {
                /**ArgosID 에서 지원하지 않는 코인 입니다 다시 선택해주세요 **/
                return toastError(`${t('coin-is-not-supported')}`);
            }

            try {
                const walletInstance: IWallet = {
                    address: walletAddress,
                    chainId,
                    walletName,
                    coinName: selectCoinTickersAndFullName.fullName,
                    coinTicker: selectCoinTickersAndFullName.ticker,
                }

                let params = {
                    body: {
                        userId: user.id,
                        alias: env,
                        requestType: 'update',
                        name: walletInstance.walletName,
                        chainId: walletInstance.chainId,
                        coinTicker: walletInstance.coinTicker,
                        coinName: walletInstance.coinName,
                        address: walletInstance.address
                    },
                };
                const updateResponse = await API.post('apiArgosID', '/wallet', params)

                if (updateResponse.statusCode !== 200) {
                    return toastError(updateResponse.message)
                }


                if (updateResponse.statusCode === 200) {
                    setWallet((prev) => [...prev, {...walletInstance, id: updateResponse.id}])
                    return toastSuccess(`${t('update-success')}`);
                }

            } catch (e) {
                return toastError(`${t('failed-update-wallet')}`);
            }
        }






        const switchNetwork = useCallback(async (currentNetwork : ICurrentSelectNetwork) => {
                try {
                    console.log(currentNetwork)
                    // check if the chain to connect to is installed
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{chainId : currentNetwork.chainId}], // chainId must be in hexadecimal numbers
                    });
                } catch (error: any) {
                    console.log(error.code)
                    if (error.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainName: currentNetwork.name,
                                    chainId: currentNetwork.chainId,
                                    rpcUrls: [currentNetwork.networkUrl],
                                    blockExplorerUrls: [currentNetwork.blockExplorerUrls],
                                    nativeCurrency : currentNetwork.nativeCurrency
                                }],
                            })
                        } catch (addError : any) {
                            if (addError.message === 'User rejected the request.') return false
                        }
                    }
                    if (error.message === 'User rejected the request.') return false
                }
        },[])




        const walletLogin = async (redirectURL: string, walletProvider: Moralis.Web3ProviderType = 'metamask') => {
            try {
                if (flag) return;
                setFlag(true);

                if (walletProvider === 'metamask') {
                    const switchRes = await switchNetwork(currentSelectNetwork);
                    if (typeof switchRes === 'boolean' && !switchRes) return ;
                }


                const user: Moralis.User<Moralis.Attributes> | undefined = await authenticate({
                    signingMessage: "ARGOS ID Authentication",
                    provider: walletProvider,
                    throwOnError: true,
                })



                if (user) {
                    setWalletLoading(true)
                    if (!alreadyUserId) {
                        const userObjectId = user.id
                        await setUpdateUser(userObjectId)
                    }
                    const walletAddress = user!.get("ethAddress");
                    const hexChainId : any = await Moralis.getChainId();
                    const chainId = (parseInt(hexChainId)) + '';
                    if (walletDuplicationVerification(chainId, walletAddress)) {
                        return toastError(`${t('wallet-already-exist')}`);
                    }

                    await updateWalletInfo(walletAddress, walletProvider)
                }
            } catch (e : any) {
                if (e.message === 'Non ethereum enabled browser' && walletProvider === 'metamask') {
                    window.open("https://metamask.io/download", "_blank")
                    return toastInfo(t('please-refresh'))
                }
                if (e.code === 4001) return;
                if (e.message === 'User closed modal') return;
                return toastError(`${t('something-is-wrong')}`);
            } finally {
                setWalletLoading(false)
                setFlag(false);
            }
        }

        const phantomLogin = async () => {
            try {
                if (flag) return;
                setFlag(true)

                if (!window.phantom) {
                    window.open("https://phantom.app/download", "_blank")
                    return toastInfo(t('please-refresh'))
                }


                const phantomConnectorUser: MoralisType.User | undefined = await authenticate({
                    type: "sol",
                    signingMessage: "ArgosID Authentication"
                });


                if (phantomConnectorUser) {
                    if (!alreadyUserId) {
                        const phantomUserObjectId = phantomConnectorUser.id
                        await setUpdateUser(phantomUserObjectId)
                    }
                    const phantomWalletAddress: string[] = phantomConnectorUser!.get("solAccounts");
                    if (walletDuplicationVerification('sol', phantomWalletAddress[0])) {
                        return toastError(t('wallet-already-exist'))
                    }
                    await updateWalletInfo(phantomWalletAddress[0], "phantom")
                }
            } catch (e) {
                console.log(e)
                return toastError(t('something-is-wrong'));
            } finally {
                setWalletLoading(false)
                setFlag(false)
            }
        }


        /** 만약에 첫로그인이여서 새로만들어주는 userid 라면 지갑 로그인할때 api 호출해서 업데이트 해주는 함수 **/
        const setUpdateUser = async (objectId: string) => {
            try {
                const setUserPath = `/user?alias=${env}&requestType=setUserId&userId=${user.id}&objectId=${objectId}`
                await API.get('apiArgosID', setUserPath, {});
            } catch (e) {
                console.log("Wallet Login setUser Error", e)
            }
        }

        const deleteWallet = async (index: number) => {
            try {
                setWalletLoading(true)
                if (walletLoading) {
                    return;
                }

                const toBeDeleted = wallet.find((_: IWallet, walletIndex) => index === walletIndex);
                if (!toBeDeleted) return;
                let params = {
                    body: {
                        address: toBeDeleted.address,
                        userId: user.id,
                        alias: env,
                        requestType: 'delete',
                        name: toBeDeleted.walletName,
                        chainId: toBeDeleted.chainId,
                        id: toBeDeleted.id
                    },
                };

                const restWallet: IWallet[] = wallet.filter((_: IWallet, walletIndex) => walletIndex !== index);

                const deleteWalletResponse = await API.post('apiArgosID', '/wallet', params)

                if (deleteWalletResponse.statusCode !== 200) {
                    setWalletLoading(false)
                    return toastError(t('remove-fail'))
                }
                if (deleteWalletResponse.statusCode === 200) {
                    setWalletLoading(false)
                    toastSuccess(t('remove-success'))
                    return setWallet(restWallet)
                }

            } catch (e) {
                return toastError(t('something-is-wrong'))
            }
        }


        const goToChangeNetwork = (step : GoToChangeNetworkParam) => {
            setNextPageAnimation(true);
            setStep(step)
        }


        if (loading) {
            return <RingsLoaderMemo/>
        }

        return (
            <section className={'flex flex-col'}>
                <h3 className={'font-bold text-sm flex items-center'}>
                    {t('verified-wallets')}
                    {walletLoading && <SmallLoading/>}
                </h3>
                {
                    wallet.length === 0 ?
                        <div className={'mt-5 leading-5 text-sm flex items-center justify-center flex-col'}
                             style={{'height': '170px'}}>
                            <FontAwesomeIcon icon={faWallet} className={'ml-1.5 text-4xl text-gray-700 cursor-pointer'}/>
                            <h4 className={'mt-3 text-center text-2xl text-gray-700'}>{t('no-verified-wallets')}</h4>
                        </div>
                        :
                        <WalletList
                            wallet={wallet}
                            deleteWallet={deleteWallet}
                        />
                }

                <WalletContentsWrapper activityCoin={activityCoin} liveFormResult={liveFormResult} step={step} wallet={wallet} collectPersonalData={collectPersonalData}/>



                <VerifyPersonWallet
                    wallet={wallet}
                    activityCoin={activityCoin}
                    goToChangeNetwork={goToChangeNetwork}
                    currentSelectNetwork={currentSelectNetwork}
                    setCurrentSelectNetwork={setCurrentSelectNetwork}
                />


                <h3 className={'font-bold my-2 text-sm'}>{t('supported-wallets')}</h3>

                <SupportedWalletsList
                    walletLogin={walletLogin}
                    phantomLogin={phantomLogin}
                    currentSelectNetwork={currentSelectNetwork}
                    goToChangeNetwork={goToChangeNetwork}
                />
            </section>
        )

    }
export default MyPage
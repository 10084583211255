import React, {useContext, useEffect, useState} from 'react';
import Button from "./common/Button";
import {IIdStatus, IProjectStatus, IUser} from "../types/type";
import {API} from "aws-amplify";
import {toastError} from "../toast";
import {useTranslation} from "react-i18next";
import {ThemeContext} from "../index";
import {useProjectOptionValue} from "../context/ArgosIdOption";

interface IFourthStepBottom {
    liveFormResult: IIdStatus | null
    AllTheCoinsAreCertified: boolean
    step: number
    goNextStep: (AllTheCoinsAreCertified ?: boolean) => void;
    user?: IUser
    projectId: string
    projectStatus: IProjectStatus
    chains: string | string[]
    env: "dev" | "live"
    collectPersonalData ?: boolean
}

const PreAuthenticationBottom: React.FC<IFourthStepBottom> =
    ({
         AllTheCoinsAreCertified,
         step,
         goNextStep,
         liveFormResult,
         user,
         projectId,
         projectStatus,
         chains,
         env,
         collectPersonalData
     }) => {
        const [consentRules, setConsentRules] = useState<boolean>(false);
        const [confirmationLoading, setConfirmationLoading] = useState<boolean>(false);
        const {t} = useTranslation();
        const theme = useContext(ThemeContext);
        const {optionArgosId} = useProjectOptionValue()



        const next = async () => {
            if (projectStatus === 'closed') return;
            if (step === 0 && AllTheCoinsAreCertified && (liveFormResult === IIdStatus.approved || !optionArgosId)) {
                let preferedOptions = '';
                if (chains && chains.length >= 1) preferedOptions = (chains as string[]).join("|").trim();
                    if (user && user.id && consentRules) {
                        try {
                            if (confirmationLoading) return;
                            setConfirmationLoading(true);

                            const res = await API.post('apiArgosID', `/confirmation?alias=${env}`, {
                                body: {
                                    userId: user.id,
                                    ...(optionArgosId && {argosId: user.argosId}),
                                    projectId,
                                    preferedOptions
                                }
                            });



                            if (res && res.statusCode === 200) {
                                if (optionArgosId && user.argosId) localStorage.setItem('aid', user.argosId);
                                localStorage.setItem('consentId' , res.consentId);
                                setConfirmationLoading(false);
                                return goNextStep(AllTheCoinsAreCertified);
                            }
                            setConfirmationLoading(false)
                            return toastError("Authentication failed , Please Retry");
                        } catch (e) {
                            setConfirmationLoading(false)
                        }
                    } else {
                        return toastError("User data is missing")
                    }

            }
            goNextStep();
        }

        useEffect(() => {
            if (!collectPersonalData) setConsentRules(true)
        },[collectPersonalData])


        return (
            <>
                <style>
                    {`
                        .small-loader:after {
                            background : #3b81f6
                        }    
                    `}

                </style>
                {
                    AllTheCoinsAreCertified && (typeof collectPersonalData === 'boolean' && collectPersonalData) &&
                    <div className={'flex text-xs  m-2 w-auto'}>


                        <div className={'flex-1 place-items-center flex items-center'}>

                            <input id={'accept'} type={'checkbox'}
                                   checked={consentRules} onChange={() => setConsentRules((prev => !prev))}
                            />
                            <label htmlFor={'accept'} className={'ml-2 text-left'}>{t('consent-to-provision')}</label>
                        </div>

                        <a className={'flex-none w-auto justify-self-end place-items-center flex items-center'}

                           style={{'minWidth': '60px'}}
                           href={'https://argos.notion.site/Consent-to-provide-personal-information-to-third-parties-8386a4821c0243648daa983787a94eeb'}
                           target={"_blank"}>
                            <span className={'cursor-pointer'}>{t('detail')} &rarr;</span>
                        </a>

                    </div>
                }

                <Button
                    onClick={next}
                    conditionalClassName={
                    ((AllTheCoinsAreCertified && consentRules) || (!AllTheCoinsAreCertified && step === 0 && ((liveFormResult === IIdStatus.approved || !optionArgosId) || liveFormResult === IIdStatus.notApproved || liveFormResult === IIdStatus.rejected) && projectStatus !== 'closed')) ?
                        {backgroundColor : theme.mainColor , color : theme.subColor} :
                        {backgroundColor : theme.mainColor , color : theme.subColor , opacity : 0.5}}
                    value={AllTheCoinsAreCertified ? t('share-my-argos-id') : t('continue')}
                    networkLoading={confirmationLoading}
                />
            </>
        )
    }
export default PreAuthenticationBottom;
import React, {CSSProperties, Dispatch, SetStateAction, useCallback, useRef} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowDown , faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {ChainId, ICurrentSelectNetwork} from "../../types/type";
import {useTranslation} from "react-i18next";
import {CHAIN_NETWORK_LIST} from "../../constant";
import useScrollPosition from "../../hooks/useScrollPosition";


interface IChangeNetWork {
    setPrevPageAnimation: Dispatch<SetStateAction<boolean>>
    setStep: Dispatch<SetStateAction<number>>
    currentSelectNetwork: ICurrentSelectNetwork
    setCurrentSelectNetwork: Dispatch<SetStateAction<ICurrentSelectNetwork>>
}


const ChangeNetwork: React.FC<IChangeNetWork> =
    ({
         setPrevPageAnimation,
         setStep,
         currentSelectNetwork,
         setCurrentSelectNetwork
     }) => {

        const goBackMyPage = () => {
            setPrevPageAnimation(true);
            setStep(2)
        }

        const scrollElement = useRef<HTMLUListElement>(null);
        const scrollPosition = useScrollPosition(scrollElement);
        const {t} = useTranslation()



        return (
            <section className={'flex flex-col items-start relative'}>
                <article className={'flex items-center w-full mt-3'}>
                    <span className={'w-full inline-block text-center'}>{t('change-network')}</span>
                </article>
                <ul ref={scrollElement} className={'flex flex-col mt-7 gap-3 w-full overflow-y-scroll max-h-[320px]'}>
                    {
                        CHAIN_NETWORK_LIST.map((chain) => {
                            return (
                                <li className={'rounded-md flex  items-center justify-between p-1.5 px-2 border-solid border border-gray-300 hover:bg-slate-100 cursor-pointer'}
                                    onClick={() => (setCurrentSelectNetwork(chain),goBackMyPage())}
                                    key={chain.chainId}>
                                <span className={'flex items-center'}>
                                    {chain.logo}
                                    <span className={`ml-3 ${currentSelectNetwork.chainId === chain.chainId && 'font-semibold'}`}>{chain.shortName}</span>
                                </span>
                                    {
                                        currentSelectNetwork.chainId === chain.chainId &&
                                        <span className={'rounded-full border-solid border-gray-300 text-sm'}>
                                            <FontAwesomeIcon icon={faCheckCircle} className={'text-green-600'}/>
                                        </span>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
                {
                    scrollPosition < 5 &&
                    <div className={'absolute bottom-[20px] flex items-center justify-center w-full text-xxxs'} >
                        <span className={'font-bold'}>Scroll Down</span>
                        <FontAwesomeIcon icon={faCircleArrowDown} className={'ml-1'}/>
                    </div>
                }
            </section>
        )
    }
export default ChangeNetwork
import React from 'react';
import {ICommonPage} from "../../types/commonModal";
import Button from "../../components/common/Button";
import {IIdStatus} from "../../types/type";
import {useTranslation} from "react-i18next";


interface IDoingLiveForm extends ICommonPage {
    loading : boolean
    liveFormResult : IIdStatus | null
}


const DoingLiveForm: React.FC<IDoingLiveForm> = ({goNextStep , loading , liveFormResult}) => {

    const {t} = useTranslation();
    const goToWallet = () => {
        if (!loading && liveFormResult === IIdStatus.approved) {
            goNextStep()
        }
        return;
    }



    return (
        <section className={'mt-5'}>
            <p>{t('live-form-is-progress-1')}</p>
            {/*<Button*/}
            {/*    conditionalClassName={`${!loading && liveFormResult ? 'bg-blue-500 ' : 'bg-blue-300 disabled:opacity-50'}`}*/}
            {/*    onClick={goToWallet}*/}
            {/*/>*/}
        </section>
    )
}
export default DoingLiveForm
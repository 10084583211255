import React, {createContext, PropsWithChildren, useState, useMemo, useContext} from 'react';
import {ValueOf} from "../types/type";


type ValueOfOptionArgosId = ValueOf<IProjectOption>

interface IOptionArgosIdContextAction {
    toggleOption: (key: keyof IProjectOption , value : ValueOfOptionArgosId) => void
}
interface IProjectOption {
    optionArgosId: boolean
}




const ProjectOptionValueContext = createContext<IProjectOption | null>(null);
const ProjectOptionActionContext = createContext<IOptionArgosIdContextAction | null>(null)


export function OptionArgosIdProvider({children}: PropsWithChildren) {
    const [optionArgosId, setOptionArgosId] = useState<IProjectOption>({
        optionArgosId: true
    })


    const actions: IOptionArgosIdContextAction = useMemo(() => ({
        toggleOption(key: keyof IProjectOption , value) {
            setOptionArgosId(prev => ({...prev, [key]: value}));
        }
    }), []);


    return (
        <ProjectOptionActionContext.Provider value={actions}>
            <ProjectOptionValueContext.Provider value={optionArgosId}>
                {children}
            </ProjectOptionValueContext.Provider>
        </ProjectOptionActionContext.Provider>
    )

}

export function useProjectOptionValue() {
    const value = useContext(ProjectOptionValueContext);
    if (!value) throw new Error('usePersistProjectOption should be used within OptionArgosIdProvider')
    return value;
}

export function useProjectOptionAction() {
    const actions = useContext(ProjectOptionActionContext);
    if (!actions) throw new Error('useProjectOptionAction should be used within OptionArgosIdProvider');
    return actions;
}
import React, {CSSProperties, memo, useMemo} from 'react';


interface SmallLoading {
    borderRightColor ?: string
    borderColor ?: string
}

const SmallLoading : React.FC<SmallLoading> = ({borderRightColor = 'skyblue'}) => {

    const spinnerLoading: CSSProperties = useMemo(() => {
        return {
            borderRightColor,
        }
    },[borderRightColor])

    return (
        <>
            <div className="spinner-2" style={spinnerLoading}></div>
        </>
    )
}
export default SmallLoading
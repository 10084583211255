import React, {CSSProperties, Dispatch, SetStateAction} from 'react';
import {useMoralis} from "react-moralis";
import 'react-toastify/dist/ReactToastify.css';
import uniqid from 'uniqid';
import MoralisType from "moralis";
import {API} from "aws-amplify";
import {IApiArgosId, IUser} from "../types/type";
import argos from '../assets/argos.svg';
import {toastError} from "../toast";
import {useTranslation} from "react-i18next";
import SmallLoading from "../components/SmallLoading";



interface ISignIn {
    setUser: Dispatch<SetStateAction<IUser>>
    setAlreadyUserId: Dispatch<SetStateAction<boolean>>
    setFirstVisit: Dispatch<SetStateAction<boolean>>
    buttonStyle : string | object
    env : "dev" | "live"
}


const SignIn: React.FC<ISignIn> = ({setUser, setAlreadyUserId, setFirstVisit, buttonStyle,env}) => {

    const {
        authenticate,
        isWeb3EnableLoading
    } = useMoralis();

    const {t} = useTranslation();



    const login = async () : Promise<unknown> => {
        try {


            if (isWeb3EnableLoading) return;

            const clientId : string = env === 'live'
                ?
                'BG3TOhyvgeJ3DnTG0f813W9wJypVvd_mDrnwVHfYMMh4zuuE6u2Ecv7nx1fvuR5ENolrFW8cRsrox5xA4w6mQ2A'
                :
                "BG3TOhyvgeJ3DnTG0f813W9wJypVvd_mDrnwVHfYMMh4zuuE6u2Ecv7nx1fvuR5ENolrFW8cRsrox5xA4w6mQ2A"


            const user: MoralisType.User | undefined = await authenticate({
                provider: "web3Auth",
                clientId: clientId,
                theme: 'light',
                loginMethodsOrder: ["google", "twitter", "linkedin", 'kakao', 'line'],
                appLogo : argos,
                rpcTarget : "https://cloudflare-eth.com",
                onComplete : () => document.getElementById('w3a-container')?.remove()
            })

            if (!user) return;
            if (user) {
                const {id: objId} = user

                const apiPath = `/user?alias=${env}&requestType=getObject&objectId=${objId}`
                const data: IApiArgosId = await API.get('apiArgosID', apiPath, {});
                if (data.userId) {
                    setUser(prev => ({
                        ...prev,
                        objectId: objId,
                        id: data.userId as string
                    }))
                    if(data.consentMarketing === undefined){
                        return setFirstVisit(true)
                    }
                    setAlreadyUserId(true);
                    return setFirstVisit(false);
                }
                const userId = uniqid();
                const setUserPath = `/user?alias=${env}&requestType=setUserId&userId=${userId}&objectId=${objId}`
                await API.get('apiArgosID', setUserPath, {});
                setUser(prev => ({
                    ...prev,
                    objectId: objId,
                    id: userId
                }))
                setFirstVisit(true)
            }

        } catch (e) {
            return toastError(t('something-is-wrong'));
        }
    }


    return (
        <div>
            <button type="button"
                    style={buttonStyle as CSSProperties ?? {}}
                    onClick={login}
                    className="argos-id-button">
                <img src={argos} alt={'argos-id'} className={'mr-1'} style={{'width': '15px', 'height': '15px' , 'marginRight' : '7px'}}/>
                {window.location.host.includes('board.argoskyc') ? t('demo-for-ARGOS-ID') : t("connect-user")}
                {isWeb3EnableLoading && <SmallLoading borderRightColor={'deepskyblue'} />}
            </button>
        </div>

    );

}

export default SignIn